////
//Variables
////

// $fa-font-path:        "../fonts";


//color variables
$brand-colorergrey :#d2d0d0;
$brand-colorgrey: #606060;
$brand-colordarkgrey: #2A2A2A;
$brand-colorlightergrey: rgba(96, 96, 96, 0.5);
$grey-text: rgba(33, 43, 54, 0.4);
$lighter-grey : #EEEEEC;

$linkedin-color:#0077B5;
$twitter-color:#2CAAE1;

$brand-tint: #EDF6F7;
$brand-light: #C4E4E7;
$brand-color: #5E8D9A;
$brand-dark: #445B61;

// Green
 
// $brand-secondary-light: rgba(129, 237, 212, 0.4);
$brand-secondary-light: #F0F8EE;
$brand-secondary:#DBEDD4;

// Yellow

// $brand-tertiary-light: rgba(255, 241, 202, 0.4);
$brand-tertiary-light: #FFF9EA;
$brand-tertiary: #FFF1CA;


//Tan

// $brand-quinary-light: rgba(236, 227, 211, 0.3);
$brand-quarterny-light: #F9F6F1;
$brand-quarterny: #ECE3D3;
 
// Stone

// $brand-quinary-light: rgba(197, 209, 215, 0.4);
$brand-quinary-light: #E8EDEF;
$brand-quinary: #C5D1D7;


// CPD colours

// Green
$cpd-green-light: rgba(168, 207, 56, 0.2);
$cpd-green: #C9D72C;
$cpd-green-dark: #8EC742;


// Yellow
$cpd-yellow-light: rgba(255, 192, 17, 0.2);
$cpd-yellow: #FFC011;
$cpd-yellow-dark: #F18F14;

// Purple
$cpd-purple-light: rgba(210, 16, 139, 0.2);
$cpd-purple: #9D228A;


// Orange
$cpd-orange-light: rgba(255, 192, 17, 0.2);
$cpd-orange: #F18F14;



// tint / grey

// $brand-tint: #FbFbFb;
// $brand-color-tint: #D8D8D8;

$brand-grey-dark:#606060;
$brand-grey-light:#9caeb0;

//status colors

$success-color:#6EAF5F;
$warning-color:#F44336;
$error-color:#F44336;


$link-color: $brand-color;
$link-highlight: darken($brand-color, 10%);

$error-color: #E6302F;


// Grid breakpoints
// taken from bootstrap 4 so we can use it on BS3 projects....
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

