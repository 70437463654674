.header-wrap{
	padding-top: 44px;
	background: $brand-color;
	padding-bottom: 1px;


	.top-nav{

		.header-tools{
			overflow: hidden;
			text-align: right;
			margin-top: 12px;

			a:not(.btn){
    			color: rgba(196, 228, 231, 0.7);

				&:hover,
				&:focus,
				&:hover:focus,
				&:active,
				&:active:hover,
				&:active:hover:focus,
				&:active:focus{
					color: #fff;
				}
			}

			.btn-secondary{
				padding: 12px 25px 10px;
				border-color: rgba(197, 209, 215, 0.52);
			}

			.list-inline{margin-bottom: 0;
				li{
					margin-left: 24px;

					a:not(.btn){
						@include heading-bold;
					}
				}
			}
		}
	}
	.member-nav-tools{
		position: absolute;
		right: 0;
		top: -50px;

	}

}


@include media-breakpoint-up(lg) {
	.navbar-brand {
		height: auto;
		width: 30rem;
		transition: all 0.1s;
		padding: 0;
		z-index: 9;
	}
}

@include media-breakpoint-up(md) {


	
}

@include media-breakpoint-down(lg) {

	.header-wrap {
		width: 100%;
		z-index: 4;
		padding-top: 20px;
		background:#5E8D9A;
		.navbar-brand{
			position: relative;
			left: 0;
			width: 190px;
			padding: 0;
		}
		.header-tools{
			float: left;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-end;
			width: 100%;
			display: none;
			
		}

	}
	#main-nav-trigger{
		margin-bottom: 20px;
		margin-left: 20px;
	}
}
@include media-breakpoint-down(sm) {

	.header-wrap{
		.top-nav{
			.header-tools {
				.member-menu{
					padding-top: 75px;
					.menu-items{
						column-count:2;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(xs) {

	
	.header-wrap{
		.top-nav{
			.header-tools {
				.member-menu{
					padding-top: 75px;
					.menu-items{
						column-gap:50px;
						.nav-item{
							margin-bottom:30px;
						}
					}
				}
			}
		}
	}
}