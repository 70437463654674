

.card.card--news{
	background: $brand-secondary-light;
	
	.green-bg--light &{
	 	background: rgba(#fff, 0.4);
	}
	&.card--img{
	 	.card__content{
	 		padding-bottom: 30px;
		}
	}
	
}

