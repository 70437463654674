.offset-banner{
	height: 400px;
	overflow: hidden;
	position: relative;
	z-index: 1;
	.img-container{

		position: absolute;
		top: 0;
		padding: 0;
		object-fit: cover;
		z-index: -1;
		max-width: none;
		min-height: 100%;
		min-width: 100%;
		img{

		}
	}

	&:after{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $brand-color;
		opacity: 0.5;
		z-index: 2;
		content: "";
	}

	& + .white-bg{
		position: relative;
		z-index: 2;
		margin-top: -150px;
	}
}

.contact{
	.banner-with-overlay{
		+ .section:not([class*="-bg"]){
			background-color: #E8EDEF;
		}
	}
}

.banner-with-overlay{
	position:relative;
	padding-top: 45px;
	min-height: 420px;

	&.search-results_filter{

		.banner__content{
			min-height: 375px;	
		}

		.btn{
			position: absolute;
			bottom: -125px;
			transform: translate(-50%, 0);
			left: 50%;
		}

	}

	&.banner__navigation{
		.banner__content{
			min-height: auto;
			padding-top: 150px;
			padding-bottom: 0;

			list-style: none;

			.banner__navigation-container {
				list-style: none;

				.banner__navigation-content{
					list-style: none;

					li{
						list-style: none;
						a{
							@include heading-medium;
							color: $brand-colorgrey;
						}
						&.active,
						&:hover{
							a{
								color: $brand-color;
							}
						}
					}
				}
			}

		}
	}


	+ .section {
		margin-top: -55px;

		padding-top: 100px;
	}

	+ .section:not([class*="-bg"]){
		background-color: #fff;
	}


	.banner__content{
		min-height: 430px;
		padding-top: 150px;
		padding-bottom: 50px;


		&:before{
			content:'';
			position:absolute;
			right:50px;
			top: 60px;
			background-image: url('../images/system/iris.svg'); 
			width: 500px;
			height: 500px;
			mix-blend-mode: multiply;
			opacity: 0.2;
		}


		

	}




	.img-container{
		position: absolute;
		top: 0;
		padding:0;
		object-fit: cover;
		z-index: -1;
		max-width: none;
		min-height: 100%;
		min-width: 100%
	}
	h1{
		color:$brand-grey-dark;
		text-align: center;
		margin:0 auto;
		& + .banner__subheading{
			margin-top: 15px;
		}
	}

	.banner__label{
		margin-top: -35px;
		font-size: 1.2rem;
		color: #fff;
		text-transform: uppercase;

		display: block;
		text-align: center;
		@include heading-bold;

		& + h1{
			margin-top: 30px;
		}
	}

	.banner__subheading{
		text-align: center;
		font-size: 3.4rem;
		line-height: 1.2em;
		color: $brand-colorgrey;
		@include sub-heading-light;
	}

	&--form{
		.banner__content{


		}
	}
}


.content-banner{
	min-height: 500px; 
	position: relative;
	text-align: center;
	&:before{
		content: "";
		background-color: rgba(94, 141, 154, 0.5);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}
	.promo__logo{
		margin-bottom: 30px;
	}
	.promo__details{
		h3{
			margin-bottom: 0;
			& + h3{
				margin-bottom: 1.5em;
			}
		}
	}
	& > img{
		width: 100%;
		height: 100%;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		object-fit: cover;
	}
	.banner__content{
		padding-top: 60px;
		position: relative;
		z-index: 4;
		padding-bottom: 60px;
		max-width: 800px;
		margin: 0 auto;
		h1,h2,h3,h4,h5,p{
			color: #fff;
		}
	}
}



@include media-breakpoint-down(lg) {

	.banner-with-overlay {
		padding-top: 115px;


		&.banner__navigation {
			.banner__content {
				.banner__navigation-content {
					margin: 50px 15px 15px;
				}
			}
		}

		&.search-results_filter{

			.banner__content{
				min-height: 320px;	
			}

			

		}

		.banner__content{
			
			min-height: 350px;
			padding-top: 100px;
			padding-bottom: 50px;

			.banner__label {
				& + h1 {
					margin-top: 20px;
				}
			}
		}
	}
}


@include media-breakpoint-up(lg) {

	.banner-with-overlay{
		&.banner__navigation{
			.banner__content{

				.banner__navigation-container {
					display: flex;
					list-style: none;

					.banner__navigation-content{
						width: 100%;
						display: flex;
						justify-content: space-evenly;
						padding-left: 0;
						margin: 100px 50px 15px;

						li{
							list-style: none;
							text-align: center;
							a{
								@include heading-medium;
								color: $brand-colorgrey;
							}
							&.active,
							&:hover{
								a{
									color: $brand-color;
								} 
							}
						}
					}
				}

			}
		}
	}
}



@include media-breakpoint-down(md) {


	.content-banner{

		.banner__content{
			padding-top: 30px;
			padding-bottom: 30px;
			max-width: 100%;
		}
	}

	.banner-with-overlay {

		h1{
			& + .banner__subheading{
				font-size: 2.4rem;
			}
		}


		&.search-results_filter{

			.banner__content{
				min-height: 295px;;	
			}

			.btn {
				bottom: -95px;
			}
		}


		&.banner__navigation {

			.banner__content {
				.banner__navigation-content {
					text-align: center;
					display: grid;
					grid-template-columns: repeat( auto-fill, minmax(150px, auto) );
					grid-gap: 20px;
					margin: 20px 0 30px;
				}
			}
		}




		.banner__content{

			.banner__label {
				& + h1 {

				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {

	.banner-with-overlay {
		
		padding-top: 150px;

		&.banner__navigation {
			.banner__content{
				padding-top: 80px;
				.banner__navigation-content {
					text-align: left;
				}
			}
		}

		&.search-results_filter{

			.banner__content{
				min-height: 295px;;	
			}

			.btn {
				bottom: -125px;
			}
		}

		.img-container{
			min-height: 180px;
		}


		.banner__content{
			
			min-height: 220px;
			padding-top: 80px;
			padding-bottom: 50px;
			margin-left: 0;
			margin-right: 0;

			&:before{
				width: 350px;
				right: 0;
				top: -130px;
				background-repeat: no-repeat;
				position: absolute;
			}

			.banner__label {
				& + h1 {

				}
			}
		}
	}
}

.banner--article{
	padding: 60px 0 0 0;

	&.with--image{

		.banner__content{

			h1{
				margin-bottom: 0;
				& + p{
					margin-top: 15px;
					margin-bottom: 0px;
					@include heading-medium;
					color: $brand-colorgrey;
					font-size: 20px;
				}
			}

			.banner__heading--image{
				display: grid;
				grid-template-columns: auto 35%;
				grid-column-gap: 30px;
				align-items: center;
				margin-bottom: 50px;

			}
		}
	}
	
	&[class*="-bg"]{
		
		.banner__content{
			border-bottom: none;
		}
		
	}


	+ .section:not([class*="-bg"]){
		
		padding-top: 0;
		
	}

	.banner__content{
		border-bottom: 1px rgba(96, 96, 96, 0.2) solid;
		h1{
			margin-bottom: 30px;
		}

		.banner__subheading{
			margin-bottom: 30px;
			@include font-bold;
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $brand-colorlightergrey;
			display: flex;
			align-items: center;

			span{
				font-size: 1.2rem;
				margin-right: 20px;

				&.banner__date{
					font-size: 1.2rem;
				}
			}

			svg{
				color: $brand-colorgrey;
				font-size: 3.0rem;
				margin-right: 15px;
			}
		}

	}

	&.banner--consultant{
		h1{
			color: $brand-colordarkgrey;
			margin-bottom: 0;
		}

		.banner__consultant-heading{
			display: grid;
			grid-template-columns: auto 35%;
			grid-column-gap: 30px;
			align-items: center;
			margin-bottom: 50px;

			img{
				widows: 100%;
				justify-self: end;
			}
		}

	}
}

.banner--awards{
	padding: 60px 0;

	&.with--image{

		.banner__content{

			h1{
				margin-bottom: 0;
				& + p{
					margin-top: 15px;
					margin-bottom: 0px;
					@include heading-medium;
					color: $brand-colorgrey;
					font-size: 20px;
				}
			}

			.banner__heading--image{
				display: grid;
				grid-template-columns: auto 35%;
				grid-column-gap: 30px;
				align-items: center;
				margin-bottom: 50px;

			}
		}
	}
	
	&[class*="-bg"]{
		
		.banner__content{
			border-bottom: none;
		}
		
	}


	+ .section:not([class*="-bg"]){
		
		padding-top: 0;
		
	}

	.banner__content{
		border-bottom: 1px rgba(96, 96, 96, 0.2) solid;
		h1{
			margin-bottom: 30px;
		}

		.banner__subheading{
			margin-bottom: 30px;
			@include font-bold;
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $brand-colorlightergrey;
			display: flex;
			align-items: center;

			span{
				font-size: 1.2rem;
				margin-right: 20px;

				&.banner__date{
					font-size: 1.2rem;
				}
			}

			svg{
				color: $brand-colorgrey;
				font-size: 3.0rem;
				margin-right: 15px;
			}
		}

	}

	&.banner--consultant{
		h1{
			color: $brand-colordarkgrey;
			margin-bottom: 0;
		}

		.banner__consultant-heading{
			display: grid;
			grid-template-columns: auto 35%;
			grid-column-gap: 30px;
			align-items: center;
			margin-bottom: 50px;

			img{
				widows: 100%;
				justify-self: end;
			}
		}

	}
}

.banner--journal{
	overflow:hidden;
	padding-top:100px;
	padding-bottom:150px;
	position:relative;

	// 
	// background-blend-mode: multiply;
	// background-size: 900px;
	// background-position: right 175px;
	// background-repeat: no-repeat;

	&:after{
		content:'';
		width: 900px;
		height: 900px;
		display: block;
		position:absolute;
		background-image:url('/images/system/iris.svg');
		mix-blend-mode:multiply;
		opacity: 0.4;
		z-index: 0;
		right: 0;
		bottom: -552px;
	}
	.banner__content{
		position:relative;
		z-index: 1;
	}
}

@include media-breakpoint-down(md){


	.banner--article.with--image{
		.banner__content{
			.banner__heading--image{
				grid-column-gap: 15px;
			}
		}
	}

}	

@include media-breakpoint-down(sm){




	.offset-banner{
		& + .white-bg{
			padding-left: 15px;
			padding-right: 15px;
		}
	}


}

@include media-breakpoint-down(sm){

	.banner--article{
		padding: 35px 0 30px;

	}

}