.panel-group{
	margin-bottom: 40px;
	margin-top: 30px;
	.panel{
		border-radius: 0;
	}
}
.panel-default{
	position: relative;
	border:0;
	box-shadow: none;
	padding: 15px 0 20px;
	margin-bottom: 20px;
	border-top: 1px $brand-light solid; 

	border-radius: 0;
	& > .panel-heading{
		position: relative;
		border:0;
		background:none;
		overflow: hidden;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;

		.panel-title{
			display: block;
			overflow: hidden;
			width:100%;
			h3{
				@include sub-heading-light;
				font-size: 3.4rem;
				color: $brand-colorgrey;
				margin-bottom: 0;
				& + p{
					margin-top: 1em;
				}
				&:after{
					content: "\f078";
					@include fontawesome-regular;
					display: none;
				}
				svg{
					font-size: 2.0rem;
					transform:rotate(180deg);
					float: right;
					margin-right: 2.5rem;
					color: $brand-colorgrey;
				}
				

			}
			&:hover{
				h3{
					color: $link-highlight;
				}
				svg{
					color: $link-highlight;
				}
			}

			&.collapsed{
				h3{
					svg{
						color: $brand-color;
						transform:rotate(0deg);
					}
				}
			}

			&:hover,
			&:focus{
				.fa-stack-2x{
					color: $link-highlight;
				}
			}
			
			[class^="fa"]{
				transition:color 0.2s ease-in-out;
			}
		}
		
		& + .panel-collapse{

			& > .panel-body{
				border-top: 0;
				padding-left: 0;
				padding-right: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}

.panel{
	box-shadow: 0;
	&:nth-last-child(1){
		border-bottom: 1px $brand-light solid; 
	}
}

.panel-group--content{
	.panel{
		padding:0;
	}
	.panel-heading{
		padding-top: 0;
		.panel-title{

			h3{
				padding:3rem 0 3rem 2.5rem;
				color:#606060;
				@include heading-bold;
				font-size: 2.6rem;
				background: #FBFBFB;
			}
			margin-bottom: 15px;
			
			&.collapsed{
				margin-bottom: 0;
				h3{
					background:transparent;
					color:$brand-color;
				}
			}
		} 
	} 
	.panel-body{
		padding: 0 2.5rem !important;
		.panel-body__details{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-top: 20px;
			padding-bottom: 50px;
			& > p{
				flex-basis:100%;
			}

		}
	
		

	}
}


@include media-breakpoint-up(md){
	.panel-group--branch{
		.panel-body{
			display: flex;
			justify-content: space-between;
			&:before,
			&:after{
				display: none;
			}
		}
	}
}

@include media-breakpoint-down(md){

	.panel-group--content .panel-heading .panel-title h3{
		font-size: 1.8rem;
	}


	.panel-group--branch{
		.panel-body{
			padding: 0 1.5rem !important;
		}

		.panel-heading{
			.panel-title{
				margin-bottom: 0;

				h3{
					
					padding: 2rem 0 2rem 1.5rem;
				}
			}
		}
	}

}

