
.default-carousel {
	margin: 6.5vh auto;
	position: relative;
    background-color: #353e41;

    .carousel-inner {
        height: 500px;

        &>.item {
            height: 100%;
            opacity: 0;
            transition-delay: 0s;
            transition: opacity 0s;

            .carousel-image-container {
                position: relative;
                height: 100%;
                width: 60%;
                overflow: hidden;
                float: left;
                z-index: 0;
                opacity: 0;
                transition: opacity 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);

                img {
                    width: 100%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                }
                .video-container{
                    padding-bottom: auto;
                    height: 100%;  
                }
            }

            .carousel-caption {
                overflow: hidden;
                width: 40%;
                position: relative;
                left: 0;
                top: 0;
                padding: 60px 50px 120px 50px;
                float: left;
                text-align: center;
                text-shadow: none;
                height: 100%;
                display: grid;

                h1 {
                    font-size: 47px;
                }
                h2{
                    margin-bottom: 0;
                    & + p{
                        margin-top: 30px;
                    }
                }

                h1,h2,h3,h4,p,a,span{
                    color: #fff;
                }

                * {
                    transition: transform 0.4s ease-out;
                    transform: translateX(0);
                }

                p {
                    transition-delay: 0.1s;
                }

                a {
                    transition-delay: 0.15s;
                }

               .carousel__subheading{

                    h3{
                        font-size: 2.0rem; 
                        @include heading-medium;
                    }

                   .carousel__label{
                        font-size: 1.2rem;
                        color: $brand-quinary;
                        text-transform: uppercase;
                        font-family: gibbs, Helvetica, Arial, sans-serif;
                        font-weight: 700;
                        font-style: normal;
                        margin-bottom: 10px;
                    }
                }

                .carousel__content{
                    align-self: end;
                }
            }

            // animations
            &.active {
                opacity: 1;

                .carousel-image-container{
                   opacity: 1;
                }

                .carousel-caption.carousel-image-container {
                    * {
                        transform: translateX(0);
                    }
                }

                &.left {
                    .carousel-caption.carousel-image-container {
                        * {
                            transform: translateX(-100%);
                        }
                    }
                }

                &.right {
                    .carousel-caption.carousel-image-container {
                        * {
                            transform: translateX(100%);
                        }
                    }
                }
            }

            &.left,
            &.right {
                padding: 0;
                transform: none;
                width: 100%;

                .carousel-image-container {
                    &:before {
                        width: 100%;
                    }
                }
            }

            &.next {
                .carousel-caption {
                    * {
                        transform: translateX(100%);
                    }
                }
            }

            &.prev {

                .carousel-caption {
                    * {
                        transform: translateX(-100%);
                    }
                }
            }

        }

    }

    .control-container {
        width: 40%;
        overflow: hidden;
        position: absolute;
        height: 100px;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        align-items: center;

        .carousel-control {
            position: relative;
            display: inline-block;
            background-image: none;
            opacity: 0.3;
            text-shadow: none;
            color: #fff;
            transition: background 0.2s ease-in-out;
            padding: 0;
            font-size: 30px;

            .left,
            .right {
                padding: 0;
            }

            span {
                top: 50%;
                left: 50%;
                transform: none;
                color: #fff;
                transform: translate(-50%, -50%);
                font-size: 30px;
                letter-spacing: 1.5px;
                line-height: 19px;
                position: absolute;
            }

            &:hover {
                color: $brand-light;
            }
        }
    }

    .carousel-indicators {
        left: 0;
        top: 0;
        bottom: unset;
        text-align: left;
        margin-left: 0;
        z-index: 2;

        li {
            background: #DEDEDE;
            border: 0;
            width: 11px;
            height: 11px;
            margin-right: 15px;

            &.active {
                background: #9B9B9B;
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .default-carousel {
        margin-top: 75px;
        margin-bottom: 75px;

        .carousel-inner {
            &:before {
                right: 45%;
            }

            &>.item .carousel-image-container {
                width: 50%;
                height: 100%;

            }

            &>.item .carousel-caption {
                display: block;
                width: 50%;
            }
            .control-container {
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .default-carousel {
        .carousel-inner {


            &:before {
                right: 45%;
                width: 80px;
                height: 150px;
            }

        }


    }

    .default-carousel {
        margin-top: 0;
        margin-bottom: 0;
        // margin-left: -15px;
        // margin-right: -15px;

        .carousel-inner {
            min-height: 500px;
            height: auto;

            &:before {
                right: 30px;
                transform: rotate(90deg);
                top: 183px;
            }

            &>.item {

                &.active,
                &.prev,
                &.next {
                    display: flex;
                }

                flex-direction:column;

                .carousel-caption {
                    width: 100%;
                    order: 2;
                    padding-top: 50px;
                    padding-left: 15px;
                    padding-right: 15px;

                    h1 {
                        margin-top: 10px;
                        font-size: 2.4em;
                        font-size: 34px;
                        margin-bottom: 15px;

                    }

                    p {
                        font-size: 18px;
                    }
                }

                .carousel-image-container {
                    width: 100%;
                    order: 1;

                    img {
                        height: auto;
                    }
                }
            }

            &>.item.active.right .carousel-caption * {
                transform: translateX(110%);
            }

            &>.item.active.left .carousel-caption * {
                transform: translateX(-110%);
            }

     

            .control-container {
                width: 100%;

                .carousel-control {
                    span {
                        font-size: 22px;
                    }

                    &.left {
                        left: 0;
                    }
                }
            }
        }
    }

}