.job-listing{
	display: grid;

	grid-column-gap:15px;
	justify-content: space-around;
	padding: 30px 0;
	border-bottom: 1px $brand-light solid; 

	.content{
		margin-right: 30px;
		h3{
			color: $brand-color;
			margin-bottom: 10px;
			transition: 0.2s ease-out color;
		}
		.date-listed{
			color: #96A4A5;
			font-size: 1.2rem;
			@include heading-bold;
			text-transform:uppercase;
			margin-bottom: 10px;
			display: block;
		}
		.extra-info{
			margin-top: 25px;
			display: flex;
			justify-content: space-between;
			.extra-info__item{
				color:#606060;
				margin-right: 15px;
				

				.svg-inline--fa{
					color:#96A4A5;
					float: left;
					font-size:2.3rem;
				}

				&:nth-last-child(1){
					margin-right: 0;
				}
			}
			.extra-info__detail{
				@include heading-medium;
				font-size: 1.6rem;
			}
			.extra-info__type{
				@include heading-bold;
				color: #96A4A5;
				font-size: 1.2rem;
				text-transform: uppercase;
				letter-spacing: 0.8px;
			}
			.extra-info__details{
				margin-left: 15px;
				float: left;
			}
		}
		a{
			&:hover{
				h3{
					color:#606060;
				}
			}
		}
	}


}

.job-featured-container{

	display: grid;
	grid-template-columns: 1fr 360px; 
	grid-column-gap:30px;
	grid-template-rows:100px auto;

	.job-featured__titles{
		grid-row-start:1;
		grid-column-start:1;

		h4{
			font-size: 1.2rem;
		}
	}
	.job-featured__listings{
		grid-row-start:2;
		grid-column-start:1;

	}	
	.job-featured__logo{

		background: #fff;
		width: 360px;
		height: 230px;
		padding:20px;
		display: flex;
		justify-content: center;
		align-items: center;


	}

}








@include media-breakpoint-up(lg){

	.job-listing{
		grid-template-columns: 600px 170px;
	}
	
}


@include media-breakpoint-up(lg){
	.job-featured-container{
		.job-featured__logo{

			grid-column-start:2;
			grid-row-start:1;
			grid-row-end:2;

		}
	}
}


@include media-breakpoint-down(md){

	.job-listing{
		.content {
			.extra-info {
				.extra-info__details{
					margin-left: 0;
				}
				.extra-info__item{
					.svg-inline--fa{
						font-size: 2rem;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.job-featured-container{
		grid-template-columns: 1fr;
		.job-featured__logo{
			width: 100%;
			grid-row-end: 1;
			margin-bottom: 30px;
		}
	}

	.job-listing{
		grid-template-columns: 1fr;

		.job-logo{
			grid-row-start:1;
			margin-bottom: 15px;
		}
		.content{
			grid-row-start:2;
		}

	}


}