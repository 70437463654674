@use "vue-select/src/scss/vue-select.scss" with (

	$vs-state-active-bg: $brand-color
	);

.v-select{
	background: #ffffff;
	border: 0;
	margin-bottom: 5px;
	font-size: 1.4rem;
	input{
		    padding: 6px 20px 10px;
		height: 46px;
   	 	font-size: 16px;

   	 	&::placeholder { 
   	 		@include heading-bold;
   	 		color: #606060;
		}
	}
	.vs__dropdown-toggle{
		border:0;
		border-radius: 0;
		padding-bottom: 0;
	}
}

.vs__search{
	font-size: 1.6rem;
}
