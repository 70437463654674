
.banner-with-overlay--form{

	.banner__content{
		min-height: auto;
		padding-bottom: 0;
	}

	#filter-form{
		.additional-filters{
			justify-content: center;
			.form-group{
				margin-right: 10px;
				&:nth-last-child(1){
					margin-right: 0;
				}
			}
		}
		.search-options-container{
			.btn{
				&.btn-link{
					margin-bottom: 30px;
				    padding: 15px 25px 20px;
				    text-transform: none;
				    font-weight: 400;
				    color: #5e8c99;
	    		}
	    		&.btn-default{
	    			margin-bottom: -22px;
	    		}

			}
		}
	}

+ .section {
		margin-top: -40px;
	
		padding-top: 100px;
	}
}



#filter-form{

	.directory-filter{
		display: flex;
		margin-top: 30px;

		.vue-input{
			width: 70%;
			flex-basis:70%;

		}
		.v-select{
			width: 30%;
			flex-basis:30%;
			background: #e8f4f6;
		}
	}

	.additional-filters{
		display: flex;
		justify-content: space-around;
		align-items:center;
		flex-wrap: wrap;
		.form-group{
			width: 30%;
			label{
				text-transform: none;
			}
		}
	}
	.btn{
		display: block;
		margin: 0 auto;

		&.btn-link{
			color:#606060;

			&:hover,
			&:focus{
				color:#606060;
			}
		}
	}

	[type="submit"]{
		// font-size:2rem;
		// padding: 25px;
		// position: absolute;
		// left: 50%;
		// transform:translate(-50%, 8px);
	}

	.search-options-container{
		#filter-form{
			.btn{
				&.btn-link{
					margin-bottom: 30px;
	    			padding: 15px 25px 20px;
	    		}
			}
		}
	}
}


.line-heading{
	margin-bottom: 40px;
	h3{
		text-align: center;
		text-transform: uppercase;
		align-items: center;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 20px;
		font-size: 14px;
		color: $brand-color;

		&:after,&:before {
			content: " ";
			display: block;
			border-bottom: 1px solid $brand-light;
		}
	}

}



.card-container{
	.row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;


		[class^="col-"]{
			margin-bottom: 30px;
			.card{
				width: 100%;
				height: 100%;
			}
		}

	}
}



@include media-breakpoint-down(md) {

	.card-filters{
		padding-left: 20px;
    	padding-right: 20px;
	}

	.banner-with-overlay--form {
		#filter-form{

			.vs__actions{
				padding: 4px 20px 0 3px;
			}
			

			.search-options-container{
				.btn.btn-link{
					position: absolute;
				    bottom: 0;
				    left: 50%;
				    right: 50%;
				    transform: translate(-50%, 0%);
				    width: 100%;
				}

				.btn.btn-default{
					margin-bottom: -22px;
    				margin-top: 80px;
				}
			}


			 .additional-filters {

			 	.form-group{
			 		margin-right: 0;
			 		padding: 5px 0;
			 	}
			 	label{
					display: none;
				}
			 }
		}

	}
	#filter-form {
		.additional-filters {
			.form-group{
				width: 100%;
				.v-select{
					background: #e8f4f6;
				}
			}
			
		}
		
	}


}


@include media-breakpoint-down(sm) {

	.search-results_filter{
		.banner-with-overlay.search-results_filter{
			 .btn{
			 	bottom: -95px;
			 }
		}
	}

	#filter-form {

		.directory-filter {
			display: block;
		
			.vue-input, .v-select{
				width: 100%;
			}

			.vue-input{
				margin-bottom: 10px;
			}

		}
	}

	.card-container{
		.row{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;


			[class^="col-"]{
				margin-bottom: 0;
				.card{
					height: auto;
				}
			}

		}
	}

}