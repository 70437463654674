.card--consultant{

	background-color:#fff;
	position:relative;

	.card__img{
		background-color:#fff;
		padding: 40px 40px 40px 20px;
		text-align: left;

		img{
			max-height: 75px;
			max-width: 200px;
		}
	}
	.nzpi-supporter{
		position:absolute;
		right: 20px;
		top: 20px;
		display: block;
		float: none;

		.nzpi-supporter__text {
			writing-mode:vertical-rl;
		}
	}

	
	.card__info{
		@include list-reset;

		.card__info-item{
			max-width: 280px;
			white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
			padding-left:20px;
			position:relative;
			margin-bottom: 10px;
			@include font-regular;
			font-size: 1.4rem;
			color: #606060;
			&:hover{
				overflow: visible;
			}
			a{
				color: #333;
				&:hover{
					color: $brand-color;	
				}
			}


			&:nth-last-child{
				.card__info-item{
					margin-bottom: 0;
				}
			}
			.svg-inline--fa{
				color: rgba(#606060, 0.2);
				position: absolute;
				left: 0;
			}

		}
	}
	

}

.nzpi-supporter{
	display: flex;
	align-items:center;
	float: left;

	&__logo{
		width: 45px;
		height: 45px;
		border-radius: 50%;
		border:2px solid $brand-color;
		margin-bottom: 10px;
		margin-right: 8px;
		flex-shrink: 0;
		display: flex;
	    align-items: center;
	    justify-content: center;
	}

	&__text {
		display: block;
		@include heading-bold;
		font-size:1.2rem;
		color:$brand-color;
		line-height: 1.4rem;
		width: 100%;
		display: flex;
		align-items:center;
		text-transform:uppercase;

	}

	& + .nzpi-supporter{
		margin-left: 15px;
	}

}

