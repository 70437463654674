.form-group{
	position: relative;
	padding:10px 0;
	margin-bottom: 0;
	width: 100%;
	.input-group{
		width: 100%;
		display: flex;
		.input-group-btn{
			width: auto;

		}
	}
	.errors{
		position: absolute;
		bottom:0;
		color: #dc3545;
	}

	.invalid-feedback{
		color: $link-highlight;
		font-size: 1.4rem;
	}

	&.invalid{
		input{
			border: #dc3545 1px solid;
			color: #dc3545;
			&:focus{
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6);
			}
			& + small{
				font-size: 1.2rem;
				color: #dc3545;
				font-style: italic;
				font-weight: 500;
			}
		}
	}

	&.is-valid{
		input{
			border: #28a745 1px solid;
			color: #28a745;
			&:focus{
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(40, 167, 69, 0.6);
			}
		}
	}

	label{
		display: inline-block;
	}
	.tooltip-toggle{
		display: inline-block;
		float: right;
		border:0;
		border-radius: 0;
		font-size: 1.7rem;
		background: transparent;
	}
	.tooltip{
		&.top .tooltip-arrow{
			border-top-color:$brand-color;
		}
		.tooltip-inner{
			background:$brand-color;
			color: #fff;

		}
	}
	
}



.form-container{
	padding: 40px 0;
}


fieldset{
	
	legend{
		flex-basis:100%;
	}
	fieldset{
		margin: 0;
		flex-basis:100%;
		legend{
			margin-top:2.5rem;
		}
	}
}


.checkbox + .checkbox, 
.radio + .radio {
	margin-top:0;
}

.checkbox,
.radio{
	padding: 10px 15px 10px 0;
}

.checkbox + .form-group{
	clear: both;
}

.inline-checks,
.inline-radios{

	overflow: hidden;
	h3{
		float: left;
		color: #fff;
		margin-right: 30px;
		@include heading-bold();
	}
	.checkbox,
	.radio,
	.custom-checkbox,
	.custom-radio{
		float: left;

		& + .checkbox,
		& + .radio,
		& +.custom-checkbox,
		& +	.custom-radio{
			margin-left: 30px;

		}
	}
}

.full-col-inputs,
.half-col-inputs,
.three-col-inputs,
.four-col-inputs{
	clear: both;

}


.half-col-inputs{
	.form-group,
	.checkbox,
	.radio{

		width: 50%;
		float: left;
	}
	.form-group{
padding: 10px 15px 10px 0;
	}
}


.three-col-inputs{
	.form-group,
	.checkbox,
	.radio{

		width: 33%;
		float: left;
	}
	.form-group{
		padding: 10px 15;
	}
}

.four-col-inputs{
	.form-group,
	.checkbox,
	.radio{
		float: left;
		width: 25%;
	}
	.form-group{
		padding: 10px 15;
	}
}

@include media-breakpoint-down(md) {
	.form-group{
		width: 100%;
		max-width: 100%;
	}
}
@include media-breakpoint-down(sm) {

	.four-col-inputs{
		.form-group{
			width: 50%;
		}
	}
	.third-checkboxes,
	.third.radios{
		.form-group{
			width: 50%;
			float: none;
		}
	}
}

@include media-breakpoint-down(xs) {
	.half-col-inputs{
		.form-group{
			max-width: 100%;
			width: 100%;
		}
	}
	.three-col-inputs,
	.half-col-inputs,
	.four-col-inputs{
		.form-group{
			width: 100%;
			float: none;
		}
	}
}

@import '_--inline.scss'

