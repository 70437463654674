
footer{

	padding: 50px 0 50px;
	position: relative;
	@include link-color($brand-color, $brand-color);

	background-color: #606060;
	color: #fff;

	@include link-color(#fff, $brand-color);

	.privacy{
		text-align: right;
		& > p{
			margin-bottom: 5px;
		}
	}

	.legal-links{
		a{
			font-size: 1.4rem;
			@include heading-bold;
			display: inline-block;
			margin-right: 10px;
			&:nth-last-child(1){
				margin-right: 0;
			}
			
		}
	}

	a{
		@include heading-semi;
		&.more{
			color: #fff;
		}
	}
	p{ 
		color: #fff;
	} 

	.contact-container{
		padding-top: 2rem;
		ul{
			justify-content:space-between;
			margin-bottom: 0;
			li{
				font-size: 2rem;
				margin-bottom: 0;
				svg{
					margin-right: 1.5rem;
				}
				
			}
			.footer_socials{
				svg{
					margin-right: 2rem;
				}
			}
		}
		
	}

	.sponsor-container{
		display: flex;
		border-top:1px solid rgba(#fff, 0.3);
		border-bottom:1px solid rgba(#fff, 0.3);
		margin-top: 4rem;
		margin-bottom: 3rem;
		padding-top: 4rem;
		padding-bottom: 1rem;

		flex-wrap: wrap;
		justify-content: space-between;
		align-items:center;



		.sponsor-title{
			margin-top: -68px;
			flex-basis:100%;

			p{
				padding-right: 2rem;
				background-color: #5f5f5f;
				display: inline-block;
				font-size: 1.3rem;
			}
		}
		
		img{
			max-width: 18.5rem;
			max-height: 5.5rem;
		}
	}
}

@include media-breakpoint-up(md) {


	.sponsor-container{



		
		.sponsor-logo{
			margin-right: 3rem;
			margin-bottom: 3rem;
		}
		
	}


}


@include media-breakpoint-down(md) {
footer{

		.privacy{
			text-align: left;
		}

}
}


@include media-breakpoint-down(sm) {

	footer{
		padding: 50px 0;

		.logo{
			display: flex;
			margin: 0 auto;
			width: 300px;
		}



		.sponsor-container{
			grid-template-columns: 50% 50%;
			justify-content: space-evenly;
			padding-top: 2.5rem;

			.sponsor-title{
				margin-top: 0;
				text-align: center;
			}

			.sponsor-logo{
				padding: 20px;
			    width: 50%;
			    text-align: center;
			}
			img{
				max-width: 15.5rem;
			}
		}
		.contact-container {
			text-align: center;
			padding-top: 4rem;
			ul {
				.footer_socials{
					svg{
						margin-left: 10px;
    					margin-right: 10px;
					}
				}
				li {
					font-size: 2rem;
					margin-bottom: 20px;
					width: 100%;
				}
			}
		}
		.legal-links{
			a{
				margin-bottom: 10px;
			}
		}
	}

}
