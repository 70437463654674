
@import "./_main-nav-tools";
@import "./_main-nav-trigger";


$nav-link-color: #fff;



#main-nav.navbar.navbar-default {
	//dimensions
	width: 100%;
	min-height: 0;
	//positioning
	padding-top: 10px;
	margin-bottom: 0;
	align-items: flex-end;
	z-index:3;
	// style
	border: 0 none transparent;
	background-color:transparent;


	.navbar-header{
		width: 100%;
	}

	.navbar-nav{
		flex-direction:row;
		margin-bottom: 0;
		// to get the content to actually be displayed evenly;

		&:before,
		&:after{
			content:none;
		}

		li {
			position: relative;
			font-size: 1.6rem;
			transition: opacity 0.2s ease-out;

			&.active{
				& > a{
					border-radius: 4px 4px 0px 0px;
					color: #fff;
					background-color: rgba(42, 42, 42, 0.6);

				}
			}

			& > a{
				padding: 15px 10px 22px;
			}

			& > .active > a,
			& > .active > a:hover,
			& > .active > a:focus {
				// BS override
				background: transparent;
			}

			& > a:not(.btn) {
				display: block;
				transition: color 0.2s ease-out, background-color 0.2s ease-out;
				opacity: hidden;
				color: $nav-link-color;
				@include heading-bold;

				&:hover {
					color: #fff;
					background-color: rgba(42, 42, 42, 0.6);
				}

			}
		}

		.dropdown{
			.close {
				display: none;
				position: absolute;

				opacity: 1;
				text-shadow: none;
				&:hover{
					color: $brand-color;
				}
			}
			&.open {

				& > a,
				& a:hover,
				& a:focus {

				}
			}
			.dropdown-menu{
				color: #2b2b2b;
				padding-top: 50px;
				padding-bottom: 30px; 

				& > li {
					
					& > a {
						white-space: nowrap;
						&:focus,
						&:hover {
							text-decoration: none;
							color: $link-highlight;
						}
					}
					&:hover {
						background-color: transparent;
						color: $link-highlight;
					}
				}

			}
		}


		&.opened{
			li:not(.open){
				opacity: 0.2;
			}
		}
	}

}


@include media-breakpoint-up(xl) {
	
	.mobile-menu-actions,
	.mobile-buttons{
		display: none;
	}







	#main-nav.navbar.navbar-default {

		.close{
			display: none;
		}

		.navbar-nav {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			flex-wrap:wrap;
			& > li{
				&.dropdown{
					& > a{
						border-radius: 4px 4px 0px 0px;
						&:hover{
							color: #fff;
							background-color: rgba(42, 42, 42, 0.6);
						}
					}
					&.open{
						& > a{
							color: #fff;
							background-color: rgba(42, 42, 42, 0.6);
						}

						& > .dropdown-menu:not(#mega-nav__menu){
							display: grid;
							grid-template-columns:1fr 1fr 1fr 1fr;
							grid-column-gap: 40px;
						}
					}
				}
			}

			.dropdown{
				position: static;



				.dropdown-menu{
					position: absolute;
					width: 100%;
					background: rgba(42, 42, 42, 0.9);
					top: 67px;
					color: #000;
					box-shadow: none;
					border: 0;
					border-radius: 0;



					& > li {
						overflow: hidden;
						& > a {
							border-radius: 4px;
							p{
								color:#a2abb0;
								white-space: normal;
								transition: color 0.2s ease-out;
								margin-bottom:0;
							}
							&:focus,
							&:hover {
								background-color: rgba(42, 42, 42, 0.9);
								color: #fff;
								p{
									color: #fff;
								}
							}
						}

						&:hover {
							background-color: transparent;
							color: $brand-color;
						}
					}
					

					&::before,
					&::after{
						content: '';
						background: rgba(42, 42, 42, 0.9);
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
					}
					&::before{
						left: -100%;
					}
					&::after{
						right:-100%;
					}
					&.open{
						display: block;
					}
					.nav-item{
						font-size: 1.6rem;
						margin-bottom: 2.5rem;
						overflow: hidden;
						max-width: 40rem;
						display: block;
						-webkit-column-break-inside: avoid;
						page-break-inside: avoid;
						break-inside: avoid;

						p{
							display: block;
							margin-top: 5px;
							@include font-regular;
							color:rgba(197,209,215,0.8)
						}
						& > a{
							@include heading-regular;
						}
					}
					.dropdown-heading{
						display: none;
					}


				}
			}
		}
	}
}

@include media-breakpoint-down(lg) {



	.mobile-menu-actions{
		background: #5b848f;
		padding:15px 15px  0 15px;
		margin:0 -15px;
		display: block;
		&__header{
			display: flex;
			justify-content: space-between;
			align-items:center;
			width: 100%;
			border-bottom:1px solid rgba(#fff, 0.1);
			margin-bottom: 0;
			padding-bottom:10px;
			.myNZPI{
				color: rgba(#2A2A2A, 0.5);
				@include heading-bold;
			}
		}
		.mobile-buttons{
			padding-top:20px;
			padding-bottom:20px;
		}
	}

	.mobile-buttons{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}


	.header-wrap{
		padding-top:0;
		.navbar-brand{
			height: 50px;
		}
	}

	.top-nav{
		padding-top:20px;
		position:absolute;
		left: 0;
		top: 0;
		width: 100%;
		background:$brand-color;
		z-index: 10;

	}

	#main-nav.navbar.navbar-default {

		align-items: center;
		position: fixed;
		background: $brand-color;
		left: 0;
		top: 0;
		margin: 0;
		padding:  0 15px;
		height: 100vh;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.2s , opacity 0.2s ease-in-out;
		overflow-y:scroll; 
		padding-top: 70px;
		border-radius:0;

		.navbar-nav {
			flex-direction: column;
			padding-top:0;
			margin:0 -15px 30px;
			& > li {

				width:100%;
				padding:0 30px;
				&:after{
					content:'';
					display: block;
					position:absolute;
					background-color:rgba(#FFFFFF, 0.4);
					height: 1px; 
					width: calc(100% - 60px);
					margin:0 auto;
					bottom:0;
				}
				

				& > a{
					white-space: normal;
					padding: 25px 20px 25px 0;
					line-height: 1.2em;

					&:active,
					&:hover,
					&:focus,
					&:hover:before,
					&:focus:before {
						color: #fff;
						background: transparent;
					}
				}

				&.close{
					position: absolute;
					right: 25px;
					bottom: 10px;
					display: block;
					// styles
					color: $link-highlight;
					text-shadow: none;
				}
			}

			.dropdown-toggle{
				overflow: hidden;
				&:after{
					content: "\f078";
					@include fontawesome-regular;
					display: none;
				}
				.fa-chevron-down{
					font-size: 2.0rem;
					transform:rotate(0deg);
					float: right;

				}
			}
			.dropdown{

				
				.dropdown-menu {
					position: relative;
					background-color: transparent;
					text-align: left;
					border: 0;
					visibility: hidden;
					overflow: hidden;
					box-shadow: none;
					float: none;
					padding: 0;
					padding-bottom: 15px;

					.nav-item{
						font-size: 20px;
						padding-bottom: 10px;
						padding-left: 15px;
						border-bottom: 0;
						overflow: hidden;

						a{
							padding-top: 15px;
							padding-left: 0;
							padding-bottom:0;
							font-size: 1.8rem;
						}
						p{
							font-size:1.6rem;
							margin-top: 10px;
							color: rgba(197,209,215,0.8);
							white-space: normal;
						}

						&:hover{
							background: #2A2A2A;
						}
					}
					
					.close {
						display: block;
					}

				}
				&.open{
					background:rgba(#2A2A2A, 0.8);
					&:after{
						opacity: 0;
					}

					& > .dropdown-menu {
						display: block;
						left: 0;
						visibility: visible;
						li > a:not(.btn){
							color:#C5D1D7;
							background:transparent;


						}
						.nav-item{

							&:hover{
								color:#fff;

							}
						}
					}

					& > .dropdown-toggle{
						.fa-chevron-down{
							transform:rotate(180deg);

						}
					}

					& > a:not(.btn){
						color: #fff;
						background:transparent;
					}
				}
			}
		}
	}

	.navigation-is-open{
		overflow: hidden;
		#main-nav.navbar.navbar-default {
			opacity: 1;
			visibility: visible;
			z-index: 4;
			display: block;


			.navbar-nav {
				li{
					opacity: 1;

				}
				
			}
		}
	}
}
