.three-piece-statement-image{
	position: relative;


	min-height: 635px;
	padding: 50px 150px 120px 120px;
	background: inherit;
	.images{
		img{
			position:absolute;
			opacity: 0;
			animation:slide-top-fade-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			isolation: isolate;
			&:nth-of-type(1n){
				left: 100px;
				top: 150px;
				animation-delay:0.4s;
			}
			&:nth-of-type(2n){
				left: 210px;
				top: 90px;
				animation:slide-bottom-fade-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				animation-delay:0.5s;

			}
			&:nth-of-type(3n){
				left: 320px;
				top: 210px;
				animation-delay:0.6s;
			}
		}

	}

	.background-graphic{

		background-repeat: no-repeat;
		background-image: url('../images/system/iris.png'); 
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		transform-origin: center;
		mix-blend-mode:multiply;
		opacity: 0.4;
	}


	&--left{
		
		img{
			&:nth-of-type(1n){
				left: 0;
			}
			&:nth-of-type(2n){
				left:110px;
			}
			&:nth-of-type(3n){
				left: 218px;
			}
		}
		.background-graphic{
			width: 631px;
			height:628px;
		}
	}
}

.statement-text{
	z-index: 2;
}

.three-piece-statement-image-container{
	display: grid;
	grid-template-columns: 1fr 530px ;

	&--left{
		grid-template-columns: 530px 1fr;
	}

}



@include media-breakpoint-down(md){






	.three-piece-statement-image-container{
		grid-template-columns: 1fr;

		&--left{
			grid-template-columns: 1fr;
		}


		.images{
			img {
    			width: 70px;
			}
		}

		.statement-text{
			padding-top: 0;
			padding-left: 15px;
			padding-right: 15px;
		}

		.three-piece-statement-image{
			padding: 15px;
			min-height: 505px;
			&--left{
				img{
				&:nth-of-type(1n){
					left: 150px;
				}
				&:nth-of-type(2n){
					left: 225px;
				}
				&:nth-of-type(3n){
					left: 300px;
				}
			}


				.background-graphic {
					width: 100%;
			    	height: 491px;
				}
			}
		}
	}

}