.sponsor-container{
	display: inline-grid;
	grid-template-columns: auto auto auto auto;
	align-items: center;
	

}

.sponsor{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	width: 262px;

	&__img{
		width: 190px;
		height: 70px;
		margin-bottom: 40px;
		display: grid;
		align-items: center;
		justify-content: center;
	}
	&__caption{
		text-align: center;
		font-size: 1.2rem;
		color:rgba(#606060, 0.5);
		width: 100%;
		text-transform: uppercase;
		@include heading-bold;
	}
}

@include media-breakpoint-down(md){
	.sponsor-container{
		grid-template-columns: auto auto;
	}
	.sponsor{
		width: 200px;

	}

}

@include media-breakpoint-down(sm){

	.sponsor{
		width: 150px;
		&__img{
			max-width: 100px;
			max-height:37px;
			margin-bottom: 40px;
		}
	}

}