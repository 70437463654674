.countDown{
	opacity: 0;
	transition:opacity 0.2s ease-out;
	min-width: 400px;
	&.active{
		opacity: 1;
	}
	span{
		padding: 15px;
		display: inline-block;
		overflow: hidden;
		text-align: center;
		color: $brand-colorgrey;
		@include font-medium;
		font-size: 4.5rem;
		small{
			display: block;
			font-size: 16px;
			text-transform: uppercase;
			border-top: 1px $lighter-grey solid;
			@include heading-medium;
			color: $brand-colorlightergrey;
		}
	}
}

@include media-breakpoint-down(sm) {

	.countDown {
	    opacity: 0;
	    transition: opacity 0.2s ease-out;
	    min-width: auto;
	}
}