
/* CPD  */



#add-project{
	.modal-dialog {
	    width: 100%;
	    padding: 100px;
	    margin: 30px auto;
	    .modal-content{
	    	padding: 50px;
	    	h3{
	    		color: $brand-color;
	    		margin-bottom: 0.5rem;
	    	}
	    	.modal-body{
	    		padding: 50px 30px;
	    	}
	    	.half-col{
	    		display: grid;
	    		grid-template-columns: 50% 50%;
			    grid-column-gap: 15px;
			    grid-template-rows: auto;
			    margin-bottom: 30px;
	    	}

	    	.full-col{
	    		margin-bottom: 30px;
	    		p{
	    			display: block !important;
	    		}
	    	}
	    	label{
	    		margin-bottom: 10px;
	    		@include heading-medium;
	    		text-transform: none;
	    		color: $brand-colorlightergrey;
	    		font-size: 1.6rem;
	    	}
	    	.form-control{
	    		@include heading-bold;
	    	}
	    }
	}
	.modal-footer{
		padding: 30px 0 0 0;

	}
	.modal-header{
		position: relative;
		padding: 50px 0;
		margin-left: 30px;
		margin-right: 30px;

		.close{
			position: absolute;
			right: 0;
			top: 0;
			svg{
				font-size: 5.0rem;
			}
		}

	}
}

.cpd-grid {

	display: grid;
	align-items: center;
	grid-template-columns: 1fr 5fr 2fr 1fr 1fr 1fr 30px 30px;
	grid-gap: 2em;
	border-bottom: 1px solid $brand-light;
	padding: 4em 0;
	label {
		font-weight: 800;
	}

	span{

	}
	.cpd__name{
		display: flex;
		align-items: center;
		svg{
			margin-right: 2em;
		}

		h3{
			@include heading-semi;
			font-size: 1.8rem;
			margin-bottom: 0;
		}
	}

	.cpd__date,.cpd__type{
		span{
			font-size: 1.4rem;
			color: $brand-colorlightergrey;
			@include heading-semi;
		}
	}
	.cpd__points{
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "raleway", Helvetica, Arial, sans-serif;
		font-weight: 700;
		height: 20px;
		width: 22px;
		background-size: cover;
		font-size: 1.6rem;
	}

	.cpd__cat1{
		color: $cpd-yellow-dark;
		.cpd__points{
			background-image: url('../images/system/icons/cpd-category-1-shape.svg');
		}
	}
	.cpd__cat2{
		color: $cpd-purple;
		.cpd__points{
			background-image: url('../images/system/icons/cpd-category-2-shape.svg');
		}
	}
	.cpd__cat3{
		color: $cpd-green-dark;
		.cpd__points{
			background-image: url('../images/system/icons/cpd-category-3-shape.svg');
		}
	}
	.cpd__edit{
		position: relative;
		text-align: right;
		cursor: pointer;
		svg{
			color: $brand-color;
		}
	}
	.cpd__comment{
		position: relative;
		text-align: right;
		cursor: pointer;
		svg{
			color: $brand-color;
		}
		.vue-popover {
		    left: auto !important;
		    right: 30px;
		    min-width: 300px;
		    text-align: left;
		    padding: 20px;
		    transform: translate(0, -50%);
    		top: 50% !important;
		    label{
		    	color: rgba(244, 245, 241, 0.6);
		    	margin-bottom: 10px;
		    }	
		    p{
		    	font-size: 1.4rem;
		    	color: #fff;
		    	margin-bottom: 0;
		    	    line-height: 1.6em;
		    }
		    background-color: $brand-color;
		    &:before{
		    	border-top: 6px solid transparent;
			    border-bottom: 6px solid transparent;
			    border-left: 6px solid $brand-color !important;
		    }
		}
	}
	.cpd__status{
		font-size: 1.4rem;
		&.status__approved{
			color: $success-color;
		}
		&.status__pending{
			color: $brand-light;
		}
		&.status__unapproved{
			color: $error-color;
		}
	}
}


.grid-header {
	label{
		text-transform: capitalize;
		@include heading-medium;
		color: $brand-color;
		display: flex;
		align-items: center;
		cursor: pointer;
		span{
			font-size: 1.4rem;
		}
		.grid__arrows{
			display: grid;
			margin-right: 10px;
			svg{
				color: $brand-light;
				&:nth-child(1){
					margin-bottom: -5px;
				}
			}
			&.arrow_up{
				svg{

					&.fa-caret-up{
						color: $brand-color;
					}
					
				}
			}
			&.arrow_down{
				svg{
					&.fa-caret-down{
						color: $brand-color;
					}
				}
			}
		}
	}
}


@include media-breakpoint-down(md) {

	.cpd-grid {

		position: relative;
		grid-template-columns: 1fr;
		grid-gap: 0.5em;

		label{
			&:first-child {
				text-align: left;
				margin: 0;
			}
		}

		.cpd__name {
			margin: 15px 0;
			svg {
				margin-right: 10px;
			}
		}

		.cpd__comment {
			text-align: left;
			margin-top: 15px;
		}

		
		&.grid-content{
			.cpd__cat1, .cpd__cat2, .cpd__cat3 {
				position: absolute;
				right: 0;
				top: 4em;
			}
		}


		&.grid-header{
			grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		}
	}

#add-project{
	.modal-dialog {
		padding: 20px;
   		margin: 15px auto;
	    .modal-content{
	    	padding: 15px;
	    	h3{
	    	}
	    	.modal-body{
	    		padding: 20px 15px;
	    	}
	    	.half-col{
	    		grid-template-columns: 1fr;
	    	}

	    	.full-col{
	    		margin-bottom: 30px;
	    		p{
	    			display: block !important;
	    		}
	    	}
	    	label{
	    		margin-bottom: 10px;
	    		@include heading-medium;
	    		text-transform: none;
	    		color: $brand-colorlightergrey;
	    		font-size: 1.6rem;
	    	}
	    	.form-control{
	    		@include heading-bold;
	    	}
	    }
	}
	.modal-footer{
		padding: 30px 0 0 0;

	}
	.modal-header{
		position: relative;
		padding: 50px 0;
		margin-left: 30px;
		margin-right: 30px;

		.close{
			position: absolute;
			right: 0;
			top: 0;
			svg{
				font-size: 5.0rem;
			}
		}

	}
}

}
