@import './_buttons.scss';

.lead,
.intro{
	margin-top: 25px;
	font-size: 2.2rem;
	line-height: 1.4em;
	margin-bottom: 25px;
	font-style: italic;
	color: $brand-color;
	@include sub-heading-regular;
}


.information-alert{
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	@include heading-medium;
	font-size: 1.4rem;
	padding-left: 30px;
	color: $brand-colorgrey;
	a{
		color: $brand-color;
		&:hover,
		&:focus{
			color: $brand-light;
		}
	}

	svg{
		font-size: 1.8rem;
		color: $brand-color;
		position: absolute;
		left: 0;
	}

}
.quote{
	font-style: italic;
	color: $brand-color;
	@include sub-heading-regular;
}

.gibbs-font{
	@include heading-regular;
}


.left{
	float: left;
	padding-right: 15px;
}
.right{
	float: right;	
	padding-left: 15px;
}
.right,
.left{
	width: 50%;
	overflow: hidden;
	overflow: hidden;
	img{
		height: auto;
		width: 100%;
	}
}
.right + .left{
	clear: both;
}
.full{
	clear: both;
	img{
		height: auto;
		width: 100%;
	}
}

.cpd{

	ol.number-list{

		li{
			padding-left: 65px;
			margin-bottom: 45px;
			h3{
				margin-bottom: 3px;
			}

			&::before{
				background: #C5D1D7;
				color: #fff;
			}
		}
	}

}

.banner-tag-line{
	margin-top: 30px;
	margin-bottom: 15px;
	width: 390px;
	position: relative;
	background-color: inherit;
	overflow: hidden;
	height: 20px;
	span{
		background-color: #fff;
		color: #000;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		padding-right: 15px;
		& + span {
			left: unset;
			right: 0;
			padding-left: 15px;
		}
	}
	&::before{
		content: '';
		height: 1px;
		width: 100%;
		background: #000;
		display: block;
		position: absolute;
		top: 50%;
	}
}

ul.standard{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	li{
		padding-left: 20px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content: "";
			background-color: $brand-colorgrey;
			position: absolute;
			left: 0;
			top: 9px;
			height: 4px;
			width: 4px;
			border-radius: 50%;
		}
	}
}



.download-list {

	padding-left: 0;
	list-style: none;
	li {
		font-size: 2.0rem;
		padding: 20px 40px;
		overflow: hidden;

		width: 100%;

		margin-bottom: 0;
		@include heading-medium;
		position: relative;
		border-bottom: #C5D1D7 solid 1px;
		line-height: 1.5em;

		a{
			color:$brand-color;

			&:hover{
				color: #606060;
			}

			small{
				color: rgba(#606060, 0.5);
				@include heading-bold;
				display: block;
				font-size: 1rem;
				line-height: 1em;
			}
			&::after{
				content:'\f56d';
				@include fontawesome-solid;
				display: none;
			}
		}
		.svg-inline--fa{
			margin-right: 1rem;
			width: 2rem;
			height: 2rem;
			color: #C5D1D7;
			position:absolute;
			left: 5px;
			top: 25px;
		}
	}
}


ol.number-list,ol{
	list-style: none;
	padding-left: 0;
	margin-bottom: 10px;

	li{
		counter-increment: step-counter;
		padding-left: 25px;
		position: relative;
		margin-bottom: 15px;
		&::before{
			content: counter(step-counter);
			font-size: 1.6rem;
			color:  #C5D1D7;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			@include heading-bold;
		}
	}
}

.row:before, .row:after{
    display: block;
}


ul.tick-list{
	@include list-reset;
	margin-bottom: 30px;
	li{
		padding-top:2px;
		padding-left: 25px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f00c';
			@include fontawesome-solid;
			display: none;
		}
		.svg-inline--fa{
			font-size: 1.2rem;
			height: 2rem;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			path{
				fill: #C5D1D7;
			}
		}
		
	}
}

ul.link-list{
	@include list-reset;
	margin-bottom: 30px;
	li{
		padding-top:2px;
		padding-left: 25px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f0c1';
			@include fontawesome-solid;
			display: none;
		}
		.svg-inline--fa{
			font-size: 1.2rem;
			height: 2rem;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			path{
				fill: #C5D1D7;
			}
		}
		
	}
}

ul.bullet-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	li{
		position: relative;
		margin-bottom: 15px;
		&::before{
			content:'•';
			color: #C5D1D7;
			position: relative;
			vertical-align:bottom;
			font-size: 3em;
		}
	}
}



.contact-list{
	@include list-reset;
	margin-left: 0;
	li{
		position:relative;
		padding-left:30px;
		margin-bottom: 15px;
		@include heading-regular;

		.svg-inline--fa{
			position:absolute;
			left:0;
			top: 3px;
			color:#C5D1D7;
		}
		a{
			color:#333;
			&:hover{
				color: $brand-color;
			}
		}

	}
}

ul.cross-list{
	@include list-reset;
	margin-bottom: 30px;
	li{
		padding-left: 40px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f00d';
			display: block;
			width: 21px;
			height: 21px;
			color: $brand-color;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			font-size: 21px;
			@include fontawesome-regular;
		}
	}
}

.two-column-list{
	column-count:2;


}
@include media-breakpoint-down(sm){

	.two-column-list{
		column-count:1;

	}

}


a.more{
	display: inline-flex; 
	align-items:center;
	@include heading-bold;
	color: $brand-color;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
	margin-top: 15px;
	font-size: 1.4rem;
	&:after{ 
		content:'\f178';
		@include fontawesome-regular;
		display: none;
	}
	& + .more{
		margin-left: 40px;
	}
	.svg-inline--fa{
		display: inline-block;
		margin-left: 10px;
		transform:translateX(0);
		transition:transform 0.15s ease-out;
		width: 1em;
		height: 1em;
	}
	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{
		.svg-inline--fa{
			transform:translateX(7px);
		}
	} 

	&--feature{
		color: $brand-colorgrey;


		svg{
			color: $brand-colorgrey;
		}
	}
}


a.back{
	display: inline-flex; 
	align-items:center;
	@include font-bold;
	color: $brand-color;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
	margin-top: 15px;
	font-size: 1.4rem;
	&:before{ 
		content:'\f177';
		@include fontawesome-regular;
		display: none;
	}
	.svg-inline--fa{
		display: inline-block;
		margin-right: 10px;
		transform:translateX(0);
		transition:transform 0.15s ease-out;
		width: 1em;
		height: 1em;
	}
	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{
		.svg-inline--fa{
			transform:translateX(-7px);
		}
	} 

	&--feature{
		color: $brand-colorgrey;


		svg{
			color: $brand-colorgrey;
		}
	}
}

a.doc{
	display: inline-block;
	@include font-medium;
	color: #2b2b2b;
	position: relative;
	padding-left: 20px;
	&:before{
		content:'\f15c';
		@include fontawesome-light;
		display: none;
	}
	.svg-inline--fa{
		position: absolute;
		direction: inline-block;
		left: 0;	
	}

	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{

		color: $brand-color;

	} 

}


.caption{
	font-style: italic;
	font-size: 1.2rem;
	margin-top: 10px;
}

@include media-breakpoint-down(md) {

	.lead,
	.intro{
		margin-top: 1em;
		font-size:1.8rem;
		line-height: 1.3em;
		margin-bottom: 1em;
	}

	.download-list {

		padding-left: 0;
		list-style: none;
		li {
			line-height: 1.3em;
			font-size: 1.4rem;
		}
	}
}


@include media-breakpoint-down(sm) {

	a.more{
		& + .more{
			margin-left: 0;
		}
	}

}
