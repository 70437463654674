.login,.signup{
	background-image: url('../images/kea.jpg');
   	background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position: 50%;
    min-height: 100vh;
    height: 100%;
	padding: 100px;

	.btn{
		margin-top: 30px;
	}

	&:before{
		content:"";
		background-color: $brand-color;
		opacity: 0.85;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	
	.form-group {

		label{
			text-transform: capitalize;
			color: $brand-colorgrey;
			
		}
		.checkbox{
			label{
				@include heading-medium;
			}
			padding-left: 0;
			label{
				color: #fff;
			}
		}
	}
	.form-container{
		margin-top: 50px;
	}
	.form-header{
		text-align: right;
	}
	.form-group{
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.input-group{
		&.input__icon{
			svg{
				color: $brand-quinary;
				position: absolute;
				z-index: 6;
				left: 10px;
				top: 16px;
				font-size: 1.6rem;
			}
		}
		input{
			padding: 10px 10px 10px 35px;
		}
	}
	.form-control{
		position: relative;
		@include placeholder-colors( $brand-colorgrey )
	}

	.form-group{
		padding-left: 0;
	}
	button{
		display: block;
	}
	legend{
		font-size: 55px;
		color: #fff;
		padding-left: 0;
	}
	h2{
		color: #fff;
		margin-bottom: 15px;
	}
	.form-footer{
	
		a{
			&.more{
				padding-right: 20px;
				color: #fff;
				margin-left: 0;
			}
		}
	}
}

.login-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
	.login-logo{
		width: 300px;
	}
	a{
		opacity: 0.7;
		font-size: 1.6rem;
		color: #fff;
		@include heading-medium;
		
		svg{
			margin-left: 10px;
		}
		&:hover{
			opacity: 1;
		}
	}
}

@include media-breakpoint-down(md) {


	.login,.signup {

		padding: 20px 15px;

		.login-header{
			display: block;
			margin-bottom: 50px;

			.login-logo{
				width: 300px;
			}
			a{
				margin-top: 30px;
				display: block;
			}
			
		}
	}

}

@include media-breakpoint-down(sm) {
	.login,.signup {
		.form-container{
			margin-top: 0;
			padding: 0px 0 20px;
		}
	}
}

.tip-section.information-error .tip-section-inner {
	background: #F9E6ED;
}

.tip-section .tip-section-inner {
    background: #EBF6F4;
    padding: 20px 30px;
    width: 100%;
    border-radius: 5px;
    display: inline-flex;
}

.tip-section.information-error svg {
    color: #C4004B;
}
svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
.tip-section svg {
    color: #3FA197;
    font-size: 32px;
    margin-right: 20px;
}
.tip-section span {
    font-family: "Campton W05 SemiBold", Helvetica, Arial, sans-serif;
    font-weight: inherit;
    font-size: 14px;
    line-height: 30px;
}