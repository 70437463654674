
.breadcrumb--nav{

	background-color: $brand-secondary-light;

.breadcrumbs{
	@include list-reset;
	margin-bottom: 0;
	padding: 20px 0;
	li {
		display: inline-block;
		margin-right: 5px;
		font-size: 1.2rem;
		text-transform: uppercase;

		&:not(:last-of-type):after{
			content: "/";
			color: $brand-colorlightergrey;
			margin-left: 5px;
		}

		a{
			color: $brand-colorlightergrey;
			@include font-bold;
		}
    	// &:nth-last-child{
    	// 	display: none;
    	// }
    }
  }


}
