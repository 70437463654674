
.btn{
	border-radius: 0; 
	border:0; 
	padding: 15px 25px 13px; 
	border-radius: 4px;
	line-height: 1em;
	transition: background-color 0.2s ease-out, color 0.2s ease-out;
	color: #fff;
	font-size: 1.5rem;
	white-space: normal;
	text-transform: uppercase;

	@include heading-bold;

	& + .btn{
		margin-left: 15px;
	}

	&.btn-primary,
	&.btn-default,
	&.btn-xs{
		color: #fff;
		background-color: $brand-color;
		border:0;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: darken($brand-color, 15%);
			border-color: $brand-color;
			box-shadow: none;
			color:	$brand-color;
			outline: none;
		}
	}

	&.btn-dark{
		color: #fff;
		background-color: $brand-color;
		border: 1px solid $brand-color;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: #fff;
			border: 1px solid $brand-light;
			box-shadow: none;
			color:	$brand-color;
			outline: none;
		}
	}


	&.btn-secondary,
	&.btn-outline{
		background: transparent;
		border: 1px solid $brand-light;
		color: $brand-light;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color:#fff;
			border-color: #fff;
			box-shadow: none;
			color: $brand-color;
			outline: none;
		}
	}
	
		&.btn-white{
		background: #fff;
		border: 0;
		color: $brand-color;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-color;
			border-color: #fff;
			box-shadow: none;
			color: #fff;
			outline: none;
		}
	}
	
	&.btn-cart{
		background: rgba(#2a2a2a, 0.5);
		color:$brand-light;
		border-radius:14px;
		font-size: 1.4rem;
		padding:8px 10px;
		.svg-inline--fa{
			path{
				fill:$brand-light;
			}
		}
	}

}