
body,html{
	scroll-behaviour: smooth;
	position: relative;
}

html{
	min-height: 100%;
}

body{
	font-size: 62.5%;
	@include font-regular;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	overflow-x: hidden;
}


@include media-breakpoint-down(lg) {
	body{
		padding-top: 70px;
	}
}

p,
li,
span,
th,
td,
button{

	font-size: 1.6rem;
	line-height: 1.4em;
	a, strong, span{
		font-size: 1em;
	}
}

p{
	@include font-regular;
	color: $brand-colorgrey;
	margin: 0 0 15px;
}


ul,ol{
	padding-left: 15px;
}
li{
	@include font-regular;
}
img{
	max-width: 100%
}

h1{
	letter-spacing: -2px;
	margin-top: 1em;
	margin-bottom: 30px;
	font-size: 5.1rem;
	line-height: 1em;
	@include heading-bold;
	color:$brand-color;
	small{
		font-size:0.5em;
	}
}

h2{
	margin-bottom: 1em;
	margin-top: 1em;
	font-size: 3.4rem;
	line-height: 1.2em;
	color: $brand-colorgrey;
	@include sub-heading-light();
}

h3{
	margin-bottom: 1em;
	font-size: 2.6rem;
	color: $brand-colorgrey;
	@include heading-bold;
}
h4{
	@include heading-medium;
	font-size: 2rem;
	color: $brand-colorgrey

}

h5{
	@include heading-bold;
	text-transform: uppercase;
	font-size: 1.2rem;
	color:#606060;
	margin-bottom: 15px;
	letter-spacing: 1.5px;

}

h4,h6{

	@include font-medium;
	margin-bottom: 1em;

}

h1, h2, h3, h4, h5, h6{
	margin-top: 0;
}

strong{
	@include font-medium;
}

a{
	color: $link-color;
	transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

	&:hover,
	&:focus{
		color: $link-highlight;
		text-decoration: none;
	}

	&.linkedin:hover,
	&.linkedin:focus{
		color: $linkedin-color;
	}
	&.twitter:hover,
	&.twitter:focus{
		color: $twitter-color;
	}

}

hr{
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

blockquote{
	border-left: 0 none transparent;
	padding: 40px 0 25px;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	@include heading-regular;
	font-size: 2.4rem;
	line-height: 1.2em;
	text-align: center;

	p{
		@include heading-regular;
		font-size: 1em;
		line-height: 1.4em;
		margin-bottom: 30px;
		color: $brand-color;
	}

}


@include media-breakpoint-down(md) {


	h1{
		font-size: 4rem;
		line-height: 1.3em;
	}

}


@include media-breakpoint-down(sm) {

	h1{
		font-size: 3rem;
		line-height: 1.3em;
	}
	h2{
		margin-bottom: 25px;
		font-size: 2rem;
	}

	h3{
		font-size: 1.8rem;
	}

	blockquote{

		max-width: 100%;
		font-size: 1.4rem;
		line-height: 1.3em;

	}
}