

.card.card--awards{
	background: #ECE3D3;
	 .card__content{
	 
	 }

}

@include media-breakpoint-down(md) {

	

}



