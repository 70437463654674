
.speaker-container{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.card{
		margin-left: 15px;
		margin-right: 15px;

	}
}
.card{

	&--speaker{
		padding: 50px 60px 30px;
		background:$brand-tint;
		width:295px;
		min-height:415px;
		.card__img{
			width: 160px;
			height: 160px;
			border-radius: 50%;
			border:10px solid #fff;
			margin: 0 auto 20px;
		}
		.card__block{
			text-align: center;
			a{
				color: #606060;
				&:hover{
					color: $brand-color;
				}
			}
		}
		.card__text{
			color:#606060;
			@include heading-bold;
		}
		.card__links{
			text-align: center;
			padding-top:25px;
			a{
				color:#606060;
				@include heading-medium;
				font-size: 2rem;
				&:hover{
					color:#000;
				}
			}
		}

	}
}

@include media-breakpoint-down(sm){

	.card{

		&--speaker{
			padding: 25px 40px 15px;
			.card__img{
				width: 120px;
				height: 120px;
				border:5px solid #fff;

			}

		}
		
	}


}
