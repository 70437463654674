
.card{
	background: #fff;
	background: $brand-tint;
	margin-bottom: 30px;

	a{
		&:hover,
		&:focus{
			.card__title{
				color: $brand-color;
				h3{
					color: $brand-color;
				}
			}

		}
	}


	.card__img{
		background: $brand-tint;
		text-align: center;
		background: #F5F5F5;
		position: relative;

	}
	.card__content{
		padding: 15px 30px 20px;
	}
	.card__subheading{
		margin-bottom: 10px;
		position: relative;

		span{
			letter-spacing: 0.5px;
			@include heading-bold;
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $brand-colorlightergrey;
			margin-bottom: 10px;
			margin-right: 10px;
			&:nth-last-child(1){
				margin-right: 0;
			}
		}
	}
	&.card__img{
		.card__content {
			height: calc(100% - 200px);
		}
	}

	.card__info{

		ul{ 
			margin-top: 20px;
			display: grid;
			grid-template-columns: 50% 50%;
			column-gap: 10px;
			row-gap: 10px;
			padding-left: 0;
			list-style: none;
			li{
				display: grid;
				align-items: center;
				padding-left: 30px;
				position: relative;
				
				.list__icon{
					left: 0;
					position: absolute;
					svg{
						color: $brand-colorlightergrey; 
						margin-right: 10px;
						font-size: 2rem;
					}
				}
				.list__content{
					& > span{
						display: inline-grid;
						font-size: 1.4rem;
						color: $brand-colorgrey;
						margin-right: 15px;
						@include heading-semi;
						& > span{
							font-size: 0.8rem;
							text-transform: uppercase;
							display: block;
							font-family: "raleway", Helvetica, Arial, sans-serif;
							font-weight: 700;
							color: rgba(96, 96, 96, 0.5);
							padding-right: 5px;

						}
					}
				}
			}
		}

		& > span{
			// display: inline-block;
   			// width: 50%;
   			// margin-bottom: 15px;
   			// padding-right: 10px;

   			font-size: 1.4rem;
   			@include font-medium;
   			svg{
   				color: $brand-colorlightergrey; 
   				margin-right: 10px;
   				font-size: 2.2rem;
   			}
   			&.cpdPoints{
   				span{
   					background: $brand-tertiary-light;
   					display: inline-block;
   					margin-right: 5px;
   					padding: 5px;
   					border-radius: 50%;
   					color: $brand-secondary;
   					@include font-bold;
   				}
   			}
   			color: $brand-colorgrey;
   		}
   	}



   	.card__date{
   		color: $brand-colorgrey;
   		font-size: 1.2rem;
   	}

   	.card__author{
   		@include sub-heading-medium;
   		font-style: italic;
   		font-size: 1.5rem;
   		margin-bottom: 20px;
   		display: block;
   		color: $brand-colorgrey;
   	}
   	.card__type{
   		font-size: 3.6rem;
   		@include font-light;
   		color: $brand-colorgrey;
   		margin-bottom: 5px;
   	}

   	.card__title{
   		color: $brand-colorgrey;
   		transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
   		h3{
   			transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
   			margin-bottom: 20px;
   			line-height: 1.3em;
   			color: $brand-colorgrey;
   		}
   		small{
   			font-size: 16px;
   			line-height: 1;
   			@include font-light;
   		}
   	}

   	.card__block{

   		p{
   			color: $brand-colorgrey;
   			font-size: 1.4rem;
   			margin-bottom:0;
   			overflow: hidden;
   			text-overflow: ellipsis;
   			display: -webkit-box;
   			-webkit-line-clamp: 4; 
   			-webkit-box-orient: vertical;
   		}
   		small{
   			color: #9B9B9B;
   			font-size: 16px;
   			@include font-medium;
   			margin-right: 15px;
   			display:block;
   		}
   	}

   	
   }



/// cards fror Submissions / development

.card--search{
	padding-bottom: 20px;
	border-bottom: 1px #e4e4e4 solid;
	margin-bottom: 20px; 
	background-color: transparent;
	.card__content{
		padding-left: 15px;
		padding-right: 15px;
	}
}



@include media-breakpoint-down(md) {

	.card{
		.card__title{
			h3{
				margin-bottom: 15px !important;
			}
		}
		.card__block{
			p{
				margin-bottom: 5px;
				max-width: 100%;
			}
		}
		.card__info {
			ul {

				grid-template-columns: 100%;
				li{
					margin-bottom: 5px;
				}

			}
		}

	}

}

