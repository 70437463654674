.panel-group--branch{
	.panel{
		padding:0;
	}
	.panel-heading{
		padding-top: 0;
		.panel-title{

			h3{
				padding:3rem 0 3rem 2.5rem;
				color:#606060;
				@include heading-bold;
				font-size: 2.6rem;
				background: #FBFBFB;
			}
			margin-bottom: 15px;
			
			&.collapsed{
				margin-bottom: 0;
				h3{
					background:transparent;
					color:$brand-color;
				}
			}
			&:hover,
			&:focus{
				h3{
					color: $brand-dark;
				}
			}
		} 
	} 
	.panel-body{
		padding: 0 2.5rem !important;
		.panel-body__details{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-top: 20px;
			padding-bottom: 50px;
			& > p{
				flex-basis:100%;
			}

		}
		.panel-body__map{
			padding-top: 20px;
		}
		.panel-body__contact-details{
			margin-top: 15px;
			margin-bottom: 15px;
			h4{
				font-size:1.4rem;
				@include font-bold;
			}
			a{
				@include font-medium;
				&:hover,
				&:focus{
					color: $brand-dark;
				}

			}
		}

	}
}


@include media-breakpoint-up(md){
	.panel-group--branch{
		.panel-body{
			display: flex;
			justify-content: space-between;
			&:before,
			&:after{
				display: none;
			}
			.panel-body__details{
				width: 66%;
				float: left;

				table{
				 	width:100%;
				 	margin-top:15px;
				 	tbody{
				 		tr{
				 			margin-top:15px;
				 			margin-bottom:15px;
				 			td{
				 				h4{
				 					margin-top:15px;
				 					font-size:14px;
				 					font-weight:700;
				 					line-height:15.4px;
				 				}
				 			}
				 		}
				 	}	
				}
			}
			.panel-body__map{
				float: left;
				width: 240px;
			}
			.panel-body__contact-details{
				flex-basis: 40%;
			}

		}
	}
}

@include media-breakpoint-down(md){
	.panel-group--branch{
		.panel-body{
padding: 0 1.5rem !important;
			.panel-body__details{
				width: 100%;
			}
			.panel-body__contact-details{


			}
			.panel-body__map{
				display: none;
			}
		}

		.panel-heading{
			.panel-title{
				margin-bottom: 0;

				h3{
					font-size: 2rem;
					padding: 2rem 0 2rem 1.5rem;
				}
			}
		}
	}

}