#carousel-content{
    height: 600px;
    position: relative;
    h1{
        margin-top: 0;
        color: #fff;
        font-weight: 300;
    }
    .carousel-inner{
        height: 100%;
        .item{
            height: 100%;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                object-fit: cover;
            }
            &:after{
                background-image: linear-gradient(180deg, rgba(94,141,154,0.00) 2%, rgba(94,141,154,0.74) 54%);
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 400px;
            }
        }
        .carousel-caption{
            text-shadow: none;
            h1,h2,h3,h4,h5,p{
                color: #fff;
            }
            a{
                color: $brand-light;
                &:hover{
                    color: #ffffff;
                }margin-right: 0;
                margin-top: 0;
            }
        }
    }
    .carousel-control{
        text-shadow:none;
        width: 100px;

        &.right,
        &.left{
            background-image: none;
            height: 100%;

            svg{
                position: absolute;
                bottom: 50px;
                font-size: 3.6rem;
            }
        }
        &.right{
            svg{
              right: 50px;  
            }
            
        }

        &.left{
            svg{
               left: 50px; 
            }
            
        }

    }
}

@media(max-width: 991px){
    #carousel-content{
        height: 450px;
    }
}

@media(max-width: 768px){

    #carousel-content{
        height: 400px;
    }

}

@media(max-width: 590px){
    #carousel-content{
       
    }
}


.quote-carousel{
    .carousel-inner{
        .item{
            padding-top: 25px;
        }
    }
    .carousel-control{
        text-shadow:none;
        width: auto;
        font-size: 30px;
        font-weight: bold;

        &.right,
        &.left{
            background-image: none;
            color: darken(#d7deda, 0.3);
        }

        &.right{
            left: 50px;
            right: auto;
        }

    }
}


main{
    .carousel-inner{
        height: 100%;
        .item{
            height: 100%;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                object-fit: cover;
            }
        }
        .carousel-control{
            .far{
                position: absolute;
                top: 50%;
                transform:tranlsateY(-50%);
            }
        }
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}