
.consultant-detail-list{

	border-top:1px solid rgba(#606060, 0.2);
	padding-top: 40px;
	padding-bottom:40px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 50px;

	@include list-reset;


	.consultant-deail-list__item{
		display: inline-block;
		@include heading-medium;
		color: $brand-colorgrey;
		a{
			color: $brand-colorgrey;
			&:hover,
			&:focus{
				color: $brand-color;
			}
		}
		svg{
			color: $brand-light;
			margin-right: 15px;
			width: 20px;
		}
	}

}

.nzpi-info{
 display: grid;
 grid-template-columns: auto auto auto;

.download-item {

		font-size: 2.0rem;
		overflow: hidden;
		padding: 20px 30px;
		margin-bottom: 10px;
		@include heading-medium;
		position: relative;
		line-height: 1.5em;

		a{
			color:$brand-color;

			&:hover{
				color: $brand-colorgrey;
			}

			small{
				color: rgba(#606060, 0.5);
				@include heading-bold;
				display: block;
				font-size: 1rem;
				line-height: 1em;
			}
			&::after{
				content:'\f56d';
				@include fontawesome-solid;
				display: none;
			}
		}
		.svg-inline--fa{
			margin-right: 0.8rem;
			width: 2rem;
			height: 2rem;
			color:$brand-light;
			position:absolute;
			left: 5px;
			top: 25px;
		}
	}
}

.banner--article{
	 .banner__content{
	 	

.consultant-top{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    align-items: center;
    margin-bottom: 30px;
    .banner__subheading{
    	margin-bottom: 0;
    }

	a.back{
		justify-self: end;
	}
}

	 }
}


@include media-breakpoint-down(md){

	.nzpi-info {
	    display: grid;
	    grid-template-columns: 100%;
	}

	.nzpi-info{
		.download-item {
    		padding: 20px 0px;
    	}
	}

	.nzpi-supporter{
		& + .nzpi-supporter {
	    	margin-left: 0;
		}

	}
	.consultant-top{
		grid-template-columns: 100%;
	
		.banner__subheading{
			order: 2;
		}
	
		a.back{
			order: 1;
			justify-self: flex-start;
		}
	}

	.consultant-detail-list{
   	 	grid-template-columns: 100%;
   	 	.consultant-deail-list__item{
   	 		padding: 10px 0;
   	 	}
	}

}
