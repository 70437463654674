

$mega-nav-trigger-bg-color: $brand-light;
$mega-nav-trigger-text-color: $brand-color;



#mega-nav-trigger{
	background: $brand-light;
	color:$brand-color;
	@include heading-bold;
	font-size:1.4rem;
	border:0;

	.svg-inline--fa{

		path{
			fill:#fff;
		}
	}
}


#main-nav.navbar.navbar-default .navbar-nav .dropdown{
		
	&.mega-nav{

		
	}
}




@include media-breakpoint-up(lg){

	.last-nav-item{
		margin-left: 10px !important;
	}

	#mega-nav-trigger{
		padding:8px 16px;
		border-radius: 4px;
		transition:color 0.2s ease-out, background 0.2s ease-out;
		&:hover{
			background:$brand-dark;
			color:#fff;
		}

		.hiden-xl{
			display: none;
		}

	}

	#main-nav.navbar.navbar-default .navbar-nav .dropdown{
		

		&.mega-nav{
			margin-right: 0;
			padding-right:0;
			order:10;
			span{
				display: none;
			}
			
			.close{
				display: block;
				position:relative;
			}
			&.open{
				#mega-nav__menu{
					opacity: 1;
					visibility: visible;
					transition:0.2s ease-out opacity, 0s visibility;
				}
			}
		}

		#mega-nav__menu{
			position:fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: rgba($brand-color, 0.95);
			z-index: 9999;
			transform:translateZ(2px);
			overflow-y: scroll;
			padding-top: 90px;
			transition:0.2s ease-out opacity, 0.2s visibility;
			opacity: 0;
			visibility: hidden;
			display: block;


			.svg-inline--fa{
				width: 2.4rem;
				height: 2.4rem;
				path{
					fill:#fff;
				}
			}
			.container{
				position:absolute;
				transform: translateX(-50%);
				left: 50%;
			}

			.mega-nav__quicklinks{
				display: flex;
				justify-content: space-between;
				align-items:center;
				flex-basis: 50%;

				a{
					color:#fff;
					@include heading-bold;
					font-size: 1.6rem;
					display: flex;
					align-items:center;
					.svg-inline--fa{
						width: 2.7rem;
						height: 2.7rem;
						margin-right: 0.6rem;
						path{
							fill:$brand-light;
						}
					}
				}

				.close{
					display: block;
					background:$brand-dark;
					color:#fff;
					border-radius:4px;
					display: inline-flex;
					align-items:center;
					padding: 8px 12px;
					transition: color 0.2s ease-out, background 0.2s ease-out;

					.svg-inline--fa{
						width: 2rem;
						height:2rem;
						path{
							fill:$brand-light;
						}
					}

					&:hover{
						background:$brand-light;
						color:$brand-color;
						.svg-inline--fa{
							width: 2rem;
							height:2rem;
							path{
								fill:$brand-color;
							}
						}
					}
				}
			}
			.dropdown{
				position:relative;
				padding-left:0;
				a.dropdown-toggle{
					color:#fff;
					padding:0;
					font-size: 1.6rem;
					padding-left: 4rem;
					background:transparent;
					@include heading-bold;

					&:focus,
					&:active{
						background:transparent;
					}
				}

				&::after{
					content:'';
					display: none;
				}
			}
			.dropdown-menu{
				position:relative;
				display: block;
				padding-top:0;
				margin-top: 1rem;
				background: transparent;
				float: none;
				top: 0;
				column-count:unset;
				padding-bottom:30px;

				.nav-item{
					margin-bottom: 0;
					padding-left:0;
					margin-left: 3.5rem;

					& > a{
						padding:6px;
					}

					.fa-chevron-down{
						display: none;
					}
				}
				&::after,
				&::before{
					display: none;
				}
			}

		}


		&.open{
			#mega-nav__menu{

				&:after,
				&:before{
					display: none;
				}

			}
		}


		.mega-nav__heading{
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			padding-bottom:30px;
			border-bottom:1px solid rgba(#fff, 0.2);
			margin-bottom: 30px;
		}

		.mega-nav__title{
			p{
				color:$brand-light;
				@include font-medium;
				margin-bottom: 0;

				strong{
					@include heading-bold;
					margin-right: 15px;
				}
			}
		}
		.mega-nav__menu-container{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap:40px;
			width: 100%;
			justify-content: space-between;
		}
		.mega-nav__list{
			padding-left:0; 
			@include list-reset;
			li{
				font-size: 1.6rem;
				color: #fff;
				padding-left:2.5rem;
				@include font-medium;
				svg{
					position:absolute;
					left: 0;
					top: 0;
				}
				small{
					@include heading-regular;
					display: block;
					color: #fff;
					font-size: 1.4rem;
				}
				.price{
					color:#FFC011;
					font-size: 1.4rem;
					display: inline-block;
				}
				&:first-of-type{
					margin-bottom: 1rem;
				}
			}
			.nav-item{
				font-size: 1.6rem;
				color: #fff;
				@include font-medium;
				a{
					color:#C4E4E7;
					font-size: 1.4rem;
					padding-left: 1rem;
					&:hover{
						color: #fff;
					}
				}
			}
		}

	}


}


@include media-breakpoint-down(lg){

			#mega-nav-trigger{
				padding: 0; 
				padding-left:0;
				text-align: left;
				background:transparent;
				color:#fff;
				font-size: 1.6rem;
				@include heading-bold;
				margin-bottom: 0;
	
				span{
					display: block;
				
				}

			}




	#main-nav.navbar.navbar-default .navbar-nav .dropdown{

			
		&.mega-nav{
			order:0;
			background:#5b848f;
			margin-left: 0;
			padding-top:20px;
			padding-bottom:20px;
			margin-right: 0;
			&:after{
				display: none;
			}
			.container{
				width: 100%;
			}
			
			.mega-nav__quicklinks{
				padding-left:0;
				margin-top: 1.5rem;
				a{
					display: block;
					font-size: 1.6rem;
					@include font-medium;
					color:#fff;
					padding: 25px 20px 25px 0;
					position:relative;
					border-top:1px solid rgba(#fff, 0.2);
					.svg-inline--fa{
						
						left: 0;
						width: 2.7rem;
						height: 2.7rem;
						path{
							fill: #fff;
						}
					}
				}
			}
		}

		#mega-nav__menu{
			position:relative;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			background: transparent;
			z-index: 9999;
			transform:translateZ(2px);
			overflow-y: scroll;
			padding-left:0;
			width: 100%;

			.container{
				padding:0;
			}

			.mega-nav__list{
				float: none;
				flex-basis: 100%;
				border-top:1px solid rgba(#FFFFFF, 0.2);
				overflow: hidden;
				@include list-reset;

				.dropdown{
					float: none;
					padding: 0;
					&.open{
							background:transparent;
						a{
							background:transparent;

						}
					}
					.dropdown-toggle{
						padding:2.5rem 2.5rem 1.5rem 3.5rem;
						color:#fff;
						small{
							display: block;
						}
						.fa-chevron-down{
							position:absolute;
							right: 0;
							top: 30px;
						}
						.svg-inline--fa:not(.fa-chevron-down){
							left: 0;
							width: 2.7rem;
							height: 2.7rem;
							position:absolute;
							path{
								fill: #fff;
							}
						}
					}
					.dropdown-menu{
						padding-left:2.5rem;
						padding-bottom:2rem;

						.nav-item{
							padding: 6px 10px;
							a{
								padding:0;
								font-size:1.4rem;
							}
						} 
					}
				}
			}

		}

	}


}