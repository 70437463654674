

.card.card--event{
	 	background: $brand-tint;
	.card__content{
		padding: 15px 30px 15px;
	}
	.card__title{
	 	h3{
	 		margin-bottom: 0;
	 	}
	}
	.card__info{
		ul{
			margin-top: 0;
		}
	}

}

@include media-breakpoint-down(md) {

	

}