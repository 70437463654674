 
@import "_buttons";


.text-center{
	text-align: center;
}
.center{
	margin-left: auto;
	margin-right: auto;
}
.img-container{
	max-width: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.5%;

	img,
	iframe{
		position: absolute;
		height: 100%;
		width: 100%;
		max-width: none;
		transform:translate(-50%, -50%);
		left: 50%;
		top: 50%;
		object-fit: cover;
	}
}

.spacer{
	margin-bottom: 50px;
}


.video-container{

	max-width: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.5%;

	iframe{
		position: absolute;
		height: 100%;
		width: 100%;
		max-width: none;
		transform:translate(-50%, -50%);
		left: 50%;
		top: 50%;
	
	}
}



.warning{
	color: #FA5E3F;
}



.align-center{
	display: flex;
	align-items: center;
}
.justify-content-center{
	justify-content: center !important;
}






.inline-card-container{
	margin-top: 40px;
	margin-bottom: 40px;
}

.nzpi-gradient{
	background-image: linear-gradient(180deg, #C4E4E7 34%, rgba(94,141,154,0.71) 100%);

	.banner-with-overlay + &{
		margin-top: -70px;
	}
	.line-heading{
		h3{
			&:before,
			&:after{
				border-color: $brand-color;
			}
		}
	}
}

.d-flex{
	display: flex;
}

.align-items-center{
	align-items:center;
}

.justify-center{
	justify-content: center;
}


.section-header{
	display: flex;
	padding-bottom:40px;
	justify-content: flex-start;
	align-items:flex-end;
	h2{
		margin-bottom: 0;
		margin-right: 2rem;
	}
}


.success{
	color:$success-color;
	svg{
		path{
			fill:$success-color;
		}
	}
	path{
		fill:$success-color;
	}
}
.warning{
	color:$warning-color;
	svg{
		path{
			fill:$warning-color;
		}
	}
	path{
		fill:$warning-color;
	}
}
.error{
	color:$error-color;
	svg{
		path{
			fill:$error-color;
		}
	}
	path{
		fill:$error-color;
	}
}




@include media-breakpoint-down(md) {

	.d-flex{
		display: block;	
	}
	
	.spacer{
	margin-bottom: 25px;
}


	.section{

		padding-bottom: 40px;
		padding-top: 40px;
	}


	.page-content{
		& + .page-content{
			margin-top: 40px;
		}
	} 

}

