

.card.card--resources{
	background: $brand-light;

	.card__content{
		display: grid;
	    height: 100%;
	}

	h3{
		font-size: 2.1rem;
		margin-bottom: 0;
	}
	.card-subheading{
		margin-bottom: 10px;
	}
	.card__info{

		display: grid;
    	align-items: flex-end;
    	
		ul{
			margin-top: 0;
			grid-template-columns: 100%;
		}
		ul{
			li{
				.list__icon{
					svg{
						color: #fff;
					}
				}
			}
		}
	}
	
}



.card.card--journal{
	background: $brand-tint;		

	h3{
		font-size: 2.1rem;
	}
	.card-subheading{
		margin-bottom: 10px;
	}
	.img-container{
		height: auto;
		padding-bottom: 0;
		img{
			position: relative;
			transform: inherit;
			left: 0;
			top: 0;
		}
	}
	
}

@include media-breakpoint-down(md) {

	.card{
		&.card--resources {
			h3{
				margin-bottom: 20px;
			}
		}
	}

}