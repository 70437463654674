
.dashboard-container{
	display: grid;

	.dashboard-tile{
		background: #fff;
		padding:30px;
		text-align: center;
		border-radius:5px;
		&__button{
			@include btn-reset;

			h2{
				font-size:2.2rem;
				@include heading-regular;
				color: $brand-color;
				margin-bottom: 0;
			}
		}
		&__icon{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 75px;
			height: 75px;
			font-size: 3.8rem;
			border-radius: 50%;
			border:0.3rem solid $brand-light;
			margin: 0 auto 10px;
		}
		p a {
			@include heading-medium;
		}
		&__content{
			
			h3{
				@include heading-medium;
				color:#606060;
				font-size:2rem;

				a{
					color:#606060;
					&:hover{
						color:$brand-color;
					}
				}
			}
			.tick-list{
				li{
					@include heading-regular;
					color:rgba(#606060, 0.6);
					margin-bottom: 0.6rem;
					font-size:1.4rem;
				}
			}

				.cpd-dashpoints{
					@include heading-regular;
					color:rgba(#606060, 0.6);
					margin-bottom: 0.6rem;
					font-size:1.4rem;
				}
	
			p{
				@include heading-regular;
				color:rgba(#606060, 0.6);
				margin-bottom: 0.6rem;
				font-size:1.4rem;
				strong{
					color:#606060;
				}
			}
			.progress{
				max-width:200px;
				height: 0.5rem;
				margin: 0 auto;
				background:rgba(#C4E4E7, 0.3);
				box-shadow: none;
				overflow: visible;
				margin-bottom: 12px;
				border-radius:0.2rem;
				.progress-bar{
					background: #C4E4E7;
					box-shadow: none;
					border-radius:2px;
				}
				&__summary{
					display: block;
					font-size:1.4rem;
					color:rgba(#606060, 0.6);
					@include heading-regular;
					margin-bottom: 2.5rem;
				}
			}

			.tick-list{
				li{
					padding-left:0;
					margin-bottom: 1.5rem;
					.svg-inline--fa{
						display: inline-block;
						width: 1.6rem;
						height: 1.6rem;
						position: relative;
						margin-left: -10px;
						margin-right: 10px;
						path{
							fill: #C4E4E7;
						}
					}
				}
			}
			.cpd-target__number{
				font-size: 2rem;
			}
			.cpd-summary{
				width: 115px;
				display: flex;
				justify-content: space-between;
				margin: 5px auto 0;
				.category{
					font-size: 2.6rem;
					@include heading-bold;
				}
			}
		}

		&__sub-heading{
			font-size:1.2rem;
			text-transform: uppercase;
			@include heading-bold;
			color:#C5D1D7;
			margin-bottom: 5px;
			display: block;
		}

		&__footer{
			width: 100%;
			margin-top: 1.5rem;
			font-size:1.6rem;
			align-self:flex-end;

			p{
				flex-basis:100%;

				padding-bottom:10px;
			}

		}
		.cdc{
			width: 130px !important;
			padding-bottom: 130px !important;
			.cdc-text{
				color:#5E8D9A;
				font-size:5.1rem !important;
				@include heading-bold;
			}
		}
		&__links{
		    border-top: dashed 1px rgba(202, 202, 202, 0.5);
		    padding-top: 2rem;
			width: 100%;
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			a{
				color:#606060;
				@include heading-bold;
				&:hover{
					color:$brand-color;
				}
			}
		}
	}
}


@include media-breakpoint-up(lg) {


	.dashboard-container{
		grid-template-columns: 1fr 1fr 1fr ;

	}

}
@include media-breakpoint-down(md) {


	.dashboard-container{
		grid-template-columns: 1fr 1fr ;
	
	}

}


@include media-breakpoint-up(md) {


	.dashboard-container{

		grid-column-gap:30px;
		grid-row-gap:30px;
		padding-top: 100px;
		padding-bottom:100px;



		.dashboard-tile{
			justify-self:stretch;
			
			&__button{
				padding-bottom:	30px;
				cursor: default;
			}
			&__content-group{
				align-self: center;
			}
			
			

		}
		.collapse{
			display: flex;
			height: calc(100% - 140px);
			flex-wrap:wrap;
			justify-content: center;
		}
		.collapse-cpd{
			display: flex;
			height: calc(100% - 183px);
			flex-wrap:wrap;
			justify-content: center;
		}
	}


}

@include media-breakpoint-down(sm) {

	.dashboard-container{
		grid-template-columns: 1fr;
		grid-row-gap:15px;
		grid-column-gap:15px;
		padding:100px 0 50px;
		.dashboard-tile{
			padding:15px;
			&__button{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items:center;
				width: 100%;

			}
			&__icon{
				margin: 0 20px 0 0 ;
			}



		}

	}



}