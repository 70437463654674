
.card--img{
	a{
		display: grid;
		height: 100%;
		align-items: start;
	}
}


@include media-breakpoint-up(lg) {

	.card--img{
		a{
			grid-template-rows: 205px auto auto;
		}
		.card__info{
			padding: 0 30px 15px;
			align-self:end;
		}

	}
}


@include media-breakpoint-down(lg) {

	.card.card--img{
		.card__content{
			align-self: flex-start;
		}

		.card__info{
			align-self: flex-end;
		}
		a{
			display: grid;
			grid-template-rows: 165px auto auto;
			height: 100%;
			align-items: start;

		}
		.img-container{

		}

		.card__info{
			display: grid;
			padding: 0px 30px 20px;
		}
	}

}



@include media-breakpoint-down(md) {

	.card.card--img{
		a{
			grid-template-rows:  auto;
		}

	}
}

@include media-breakpoint-down(sm) {
	.card.card--img{
		a{
			grid-template-rows:  auto;
		}
		
		.card__content{
			padding-bottom:0;
		}
	}
}