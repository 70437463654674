/////
//search results
/////


#search-modal{
	margin-bottom: 2em;
	margin-top: 1em;
	
	&.fade{
		.modal-dialog{
			transform: translate(0, -50px);
		}
		&.in .modal-dialog{
			transform: translate(0, 0);
		}
	} 

	.modal-dialog{
		display: flex !important;
		align-items: center;
		height: 100%;
	}

	.modal-header{
		border-bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h2{
			@include heading-bold;
			color:$brand-color;
			margin-bottom: 0;
		}
		&::before,
		&::after{
			display: none;
		}

	}
	.modal-content{
		background-color: #fff;
		width: 100%;
		.modal-body{
			background-color: transparent;
			padding:0 0 20px;
		}
	}

	.input-group{
		display: flex;

	}
	.form-control{
		padding: 0 0 0 10px;

		padding: 30px 8px;
		border:0;
		border-radius: 0;

		font-size: 2.4em;
		@include font-light; 
		&:focus{
			box-shadow: none;
			outline: 0;
		}
	}
	form{
		margin-top: 2em;
		padding: 0 2em;
		.input-group-btn{
			border: none;
			display: inline-block;
			width: auto;

		}
		.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group{
			position: relative;
		}
	}

}
