
.section{
	padding-top: 60px;
	padding-bottom: 60px;

	&--bg-image{
		background-image: url("../images/system/iris-half-bg.png");
	    background-repeat: no-repeat;
	    background-position: right bottom;
	    background-size: 800px;
	}

	&--spaced{
		padding-top: 100px;
		padding-bottom: 100px;
	}
	&--seperator{
		border-top: 1px solid #E2E2E2;
		&:first-of-type{
			border:0;
		}

	}
	&--no-space{
		padding-top:0
	}
	&--justified{
		.row{
			
			&::before,
			&::after{
				display: none;
			}
		}
	}


	.card-container{
		.row{
			justify-content: center;
		}
	}
}

.section:not([class*="bg"]){

	+ .angle--reverse{
		margin-top: 50px;
	}

	+ .section:not([class*="bg"]){
		padding-top: 0;


		.column--content{

			& *:nth-child(1){
				&.lead{
					margin-top:0;
				}
			}

		}


	}
}

.page-hero{

	+ .section:not([class*="bg"]){

		.column--content{

			& *:nth-child(1){
				&.lead{
					margin-top:0;
				}
			}

		}

	}
}

.section:last-of-type{
	& + footer{
		margin-top: 0;
	}
}


.section-heading{
	margin-bottom: 20px;
	h2{
		& + h3{
			padding-top: 15px;
		}
	}
}

.section__label{
		font-size: 1.2rem;
	    color: $brand-colorgrey;
	    text-transform: uppercase;
	    font-family: gibbs, Helvetica, Arial, sans-serif;
	    font-weight: 700;
	    font-style: normal;
	    display: block;
	    margin-bottom: 10px;
	}

.page-intro{
	font-size: 2.6rem;
	line-height: 1.4em;
	color: $brand-colorgrey;
	@include sub-heading-light;
}


.column--content{

    padding-top: 20px;
    padding-bottom: 20px;

	.img-container{
		margin-bottom: 20px;
	}
	& > img{
		margin-bottom: 20px;
	}

	 


}



.cta-column{
	border-top:1px solid $brand-quinary-light;
	border-bottom:1px solid $brand-quinary-light;
	padding-top:50px;
	padding-bottom:50px;
	margin-left: 30px;
    margin-right: 30px;
	img{
		margin:0 auto;
height: 80px;
		display: block;
	}
	h3{
		text-align: center;
		font-style: italic;
		margin-top: 20px;
		font-size: 2.4rem;
		color: $brand-color;
		@include font-light;
		line-height: 1.3;
		strong{
			font-style: normal;
			@include font-bold;
		}
	}
	p{
		margin-top: 20px;
		text-align: center;
	}

	.svg-inline--fa{

		font-size:4.4rem;
		transform: translateX(0);
		transition: transform 0.15s ease-out;
	}
	&:hover{
		svg{
			transform: translateX(7px);
		}
	}
}


@include media-breakpoint-up(md){

	.page-intro{
		margin-left: 100px;
	    margin-right: 100px;

	}
}


@include media-breakpoint-down(md){

	.section{
		padding-top: 50px;
		padding-bottom: 50px;

		&--spaced{
			padding-top: 60px;
			padding-bottom: 60px;
		}
		&--multi-column{
			.section__content{
				margin-bottom: 30px;
			}
		}

	}

	.page-intro{
		font-size:1.8rem;
		line-height: 1.3em;
	}

	.cta-column{
		text-align: center;
	}

}


@include media-breakpoint-down(sm){

	.section{


		&--spaced{
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&--multi-column{
			.section__content{
				margin-bottom: 30px;
			}
		}

	}
}
