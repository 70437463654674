
.statement-text{

	padding-top:130px;

	h1{
		font-size: 6.8rem;
		color:$brand-color;
		margin-bottom: 20px;
	}
	.lead{
		font-style: italic;
		margin-bottom: 50px;
		margin-top: 20px;
		font-weight: 500;
	}
}

.list-call-to-action{
	@include list-reset;
	
	li{
		position:relative;
		padding-left:45px;
		font-size:2rem;
		@include sub-heading-medium;
		margin-bottom:20px;
		font-style: italic;
		strong{
			@include heading-bold;
			font-style: italic; 
		}
		a{
			color: #606060;
			&:hover,
			&:focus{
				color: #fff;
			}
		}
		&:before{
			content:'\f178';
			@include fontawesome-light();
			display: none;

		}
		.svg-inline--fa{
			position:absolute;
			left: 0;
			top: -5px;
			color: #fff;
			font-size: 3.7rem;
			line-height: 2.8rem;
		}
	}
}

.home-spacing{
	padding-bottom:50px;
	h2{
		font-weight: 800;
	}
	
	.section-heading{
		margin-bottom: 10px;
		h1{
			margin-bottom: 0;
			font-size: 40px;
		}

		a.more{
			&--feature{
				margin-bottom: 0;
				 margin-left: 20px;
				 font-size: 1.6rem;
			}
			.svg-inline--fa {
			    margin-left: 7px;
			    width: 2em;
			    height: 1.5em;
			}
		}
	}
}

.event-list{
	margin-bottom: 3rem;
	&__item{
		display: flex;
		align-items:flex-start;
		padding:3rem 1.5rem;
		border-top:1px solid #fff;
		&:last-of-type{
			border-bottom:1px solid #fff;
		}
		.date{
			color: $brand-color;
			text-align: center;
			margin-right: 40px;

			span{
				display: block;
				margin-bottom: 0;

			}
			.month{
				font-size:2rem;
				@include heading-bold;
			}
			.day{
				font-size:4.4rem;
				@include heading-regular;
				line-height: 1em;
			}
		}
		.content{
			h3,p{
				color:#fff;
			}
			h3{
				font-size: 2rem;
				margin-bottom: 10px;
				@include heading-bold;
			}
			p{
				font-size: 1.4rem;
				@include sub-heading-medium;
				max-width: 35rem;
				margin-bottom: 1rem;
				a{
					color:$brand-grey-dark;
					font-style:italic;
					&:hover{
						color:#fff;
					}
				}
			}
		}
		.location{
			font-size: 1.4rem;
			color:$brand-grey-dark;
			display: inline-flex;
			&:before{
				@include  fontawesome-regular();
				content:'\f041';
				display: none;
			}
			svg{
				font-size: 2rem;
				margin-right: 0.5rem;
				display: inline-block;
			}
			span{
				font-size: 1.4rem;
			}
		}
	}
}



@include media-breakpoint-down(md) {
	.list-call-to-action {
		li{
			padding-left: 0;
			padding-right: 45px;
			margin-bottom: 20px;
			.svg-inline--fa{
				right: 0;
				left: auto;
			}

			a{
				font-size: 2rem;
			}
		}
	}
	.statement-text {
		    padding-top: 0;
		h1 {
			font-size: 4rem;
		}
	}

	.home-spacing{
		 .section-heading {
		 	margin-bottom: 30px;
		 	a.more--feature{
		 		margin-left: 0;
		 	}
		 }
	}



}


@include media-breakpoint-down(sm) {


}