
@include media-breakpoint-up(lg) {

	#main-nav-trigger {
		display: none;
	}

}
@include media-breakpoint-down(lg) {

	#main-nav-trigger {

		position:absolute;
		right: 15px;
		top: 15px;
		//dimensions
		height: 40px;
		width: 40px;
		border-radius: 50%;
		padding: 0;
		//style
		background-color:transparent;
		border:0;
		// 
		display: inline-block;
		overflow: hidden;
		transition: transform 0.2s ease-out ;
		z-index: 999;

		.main-nav-icon{
			background: transparent;
			@include center('center');
			position: absolute;
			width: 26px;
    		height: 4px;
			background-color: #C4E4E7;
			cursor: pointer;
			&:after, 
			&:before{
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color:	#C4E4E7;
				transform: translateZ(0);
				backface-visibility: hidden;
				transition: transform 0.5s, width 0.3s, top .3s;
			}
			&::before {
				transform-origin: center;
				transform: translateY(-10px);
			}
			&::after {
				transform-origin: center;
				transform: translateY( 10px);
			}
		}
		.no-touch &:hover .main-nav-icon::after {
			top: 2px;
		}
		.no-touch &:hover .main-nav-icon::before {
			top: -2px;
		}
		.navigation-is-open & {

			transform: rotate(180deg);
			.main-nav-icon{
				background-color: transparent; 
			}
			.main-nav-icon::after,
			.main-nav-icon::before {
				// animate arrow --> from hamburger to arrow 

				transition: transform 0.5s;
				transition: width 0.5s;
			}
			.main-nav-icon::before {
				transform: rotate(45deg);

			}
			.main-nav-icon::after {
				transform: rotate(-45deg);

			}
			.no-touch &:hover .main-nav-icon::after,
			.no-touch &:hover .main-nav-icon::before {
				top: 0;
			}
		}
	}
}