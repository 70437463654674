textarea{
	resize:vertical;
	min-height: 150px;
	&.form-control{
		padding: 20px;
	}
	
}
input,
.form-control{
	box-shadow: none;
	
	@include placeholder-styles(){
		color: $brand-colorlightergrey;
		@include font-regular;
		font-size: 1.4rem;
	}

}

input::placeholder{
	@include font-regular;
}

select.form-control{

	// -webkit-appearance: none;

	// &:after{
	// 	content:'\f078';
	// 	position: absolute;
	// 	display: block;
	// 	@include fontawesome-light;
	// 	top: 25px;
	// 	right: 10px;
	// 	color: #2b2b2b;
	// 	font-size: 14px;
	// }

}


.upload-icon {
    color: #919D9D;
    position: absolute;
    display: flex;
    align-items: flex-end;
    height: 100%;
    z-index: 6;
    right: 30px;
    top: 0;
    font-size: 12px;
    padding-bottom: 10px;
    color: #000;
    svg{
    	font-size: 28px;
    	margin-right: 3px;
    }
}

label{
	display: block;
	color: $label-color;
	font-size: 1.2rem;
	text-transform: uppercase;
	@include font-regular();
}

.radio input[type="radio"],
.radio-inline input[type="radio"], 
.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"]{
	margin-left: 0;

}
.checkbox{
	position: relative; 
	label::after{
		color: white;
	}
	input:checked ~ label:before{
		background: #fff;
	}
} 

.radio{
	label::after{
		background: $brand-color;
		top: 0;
		left: 0;
		height: 19px;
		width: 19px;
		border:3px lighten($brand-color, 20%) solid ;
	}
	label::after{
		background: $brand-color;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		border:3px lighten($brand-color, 20%) solid ;
	}
} 

.required{

	label{
		&:after{
			content: '\f069';
			@include fontawesome-regular;
			display: none;
		
		}
		.svg-inline--fa{
			width: 0.8em;
			 height: 0.8em;
				display: inline-block;
			margin-left:10px;
			path{
				fill:red;
			}
		}
	}
}

.errmsg{
	@include font-bold;
	color: $error-color;
	padding: 10px 10px 8px;
	background: #EADFDF;
	margin-bottom: 15px;
}


legend{
	line-height: 1.4em;
	color: $legend-color;
	font-size: 4rem;
	margin-bottom: 1rem;
	border-bottom: 0;
	& ~ fieldset legend{
		font-size: 2.4rem;
	}
}


.form-control{
	background: #F4F5F5;
	border-radius: 3px !important;
    padding: 10px;
	border: none;
	height: $input-height;
	margin-bottom: 5px;

	& + small{
		display: block;
		margin-top: 5px;
		color: $brand-color;
		font-weight: 700;
		margin-bottom: 0;
		font-size: 1.4rem;
		font-style: italic;
	}

	&:focus{
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba($brand-color,.6);
	}

}


.vs__dropdown-toggle{
	border:none;
	background: #F4F4F4;
}





