.masthead-details{
	border-top: 1px rgba(96, 96, 96, 0.2) solid;
	padding-top: 30px;
	margin-bottom: 30px;

	&.masthead-details--awards{
		.masterhead-information{
			grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
		}
	}
}


.masterhead-information{

	@include list-reset;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px;

	.masthead__item{
		padding-left: 45px;
		position: relative;
		display: flex;
		align-items: center;

		.masthead__icon{
			font-size: 2.8rem;
			left: 0;
			position: absolute;

			&.cpd-shape{
				font-size: 2rem;
				width: 3.5rem;
				height: 3.2rem;
			}

			svg{
				color: #C5D1D7;
			}

		}

		.masthead__content{

			& > span{
				display: inline-grid;
				@include heading-semi;
				font-size: 2.0rem;
				color: $brand-colorgrey;
				margin-right: 30px;

				&:last-of-type{
					margin-right: 0;
				}

				& > span{
					font-size: 1.0rem;
					text-transform: uppercase;
					display: block;
					@include font-bold;
					color: $brand-colorlightergrey;
				}
			}

		}
	}
}



.masterhead-actions{
	@include list-reset;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 30px;
	margin-top: 30px;
	
	li{
		a{
			@include heading-semi;
			color: $brand-colorlightergrey;
			&:hover{
				color: $brand-color;
			}
			.svg-inline--fa{
				margin-right: 10px;
				width: 2.4rem;
				height: 2.4rem;
			}
		}
		

	}
}

@include media-breakpoint-down(sm){

	.masterhead-information{
    	margin-bottom: 35px;
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-gap: 15px;
		.masthead__item{
			.masthead__icon{

			}
			.masthead__content{
				font-size: 2rem;
				& > span{
					font-size: 1.6rem;
				}
			}
		}
	}

	.masterhead-actions{
		grid-gap: 15px;
		margin-top: 10px;

		li a .svg-inline--fa{
			width: 2rem;
			height: 2rem;
		}
	}
}
