//- Vars

$input-height:50px;
$input-color: #fff;
$label-color: #51534a;
$legend-color: #000 ;



//- Elements
@import 'elements/_form-elements.scss';
@import 'elements/_custom-radio-check.scss';
@import 'elements/_custom-select.scss';
@import 'elements/_step-counter.scss';
// @import 'elements/_custom-fileUpload.scss';

// //- Layouts
@import 'layouts/_general-form.scss';
@import 'layouts/_--inline.scss';


// //- Unique Forms

// @import 'unique-forms/_application.scss';