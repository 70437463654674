 //fonts



@mixin fontawesome-light(){
	font-family: "Font Awesome 5 Light";
	font-weight: 300;

}
@mixin fontawesome-solid(){
	font-family: "Font Awesome 5 Solid";
	font-weight: 900;
}
@mixin fontawesome-regular(){
	font-family: "Font Awesome 5 Regular";
	font-weight: 500;
}
@mixin fontawesome-brand(){
	font-family: 'Font Awesome 5 Brands';
	font-weight: normal;
}

@mixin font-light(){

	font-family: 'raleway' , Helvetica, Arial, sans-serif;
	font-weight: 300;
	letter-spacing: 0.2px;
}
@mixin font-regular(){
	font-family: 'raleway' , Helvetica, Arial, sans-serif;
	font-weight: 400;
}
@mixin font-medium(){
	font-family: 'raleway' , Helvetica, Arial, sans-serif;
	font-weight: 500;
}

@mixin font-bold(){
	font-family: 'raleway' ,  Helvetica, Arial, sans-serif;
	font-weight: 700;
	
}

@mixin sub-heading-light(){
	font-family: raleway , Helvetica, Arial, sans-serif;
	font-weight: 300;

}
@mixin sub-heading-regular(){
	font-family: raleway , Helvetica, Arial, sans-serif;
	font-weight: 400;

}
@mixin sub-heading-medium(){
	font-family: raleway ,  Helvetica, Arial, sans-serif;
	font-weight: 500;

}
@mixin sub-heading-bold(){
	font-family: raleway ,  Helvetica, Arial, sans-serif;
	font-weight: 700;

}



@mixin heading-regular(){
	font-family: gibbs , Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin heading-semi(){
	font-family: gibbs , Helvetica, Arial, sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin heading-medium(){
	font-family: gibbs , Helvetica, Arial, sans-serif;
	font-weight: 600;
	font-style: normal;
}

@mixin heading-bold(){
	font-family: gibbs , Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
}

