.pagination-container{
	text-align:center;


}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover{
	background-color: $brand-color;
	border-color: $brand-color;
}

.pagination > li > a,
.pagination > li > span{
	color: $brand-color;
	@include font-medium;
}