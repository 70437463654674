

.member-type-legend{

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.member-type{
		padding: 0 10px;
	}

}
.member-type{
	display: inline-flex;
	align-items: center;
	
	&__symbol{
		width: 45px;
		height: 45px;
		margin:5px;
		border-radius: 50%;
		border:1px solid $brand-color;
		float: left;
		display: flex;
		align-items: center;
		justify-content: center;
		&--superstar{
			background:	#ece3d4;
		}
		&--fellow{
			background:	#dbedd5;
		}
		&--international{
			background:	#f9e0c9;
		}
		&--1{
			background:	#dededf;
		}
		&--2{
			background:	#fff0cc;
		}

	}

	&__text{
		font-size: 1.2rem;
		line-height: 1.4rem;
		@include heading-bold;
		text-transform: uppercase;
		color: $brand-color;

	}


}


.registry-item{
	border-top:#C5D1D7 1px solid;
	padding:10px 20px;
	display: grid;
	grid-template-columns:22rem 1fr;

	&__text{
		
		padding-right:2rem;
	}
	&__detail,
	&__title{
		margin-bottom:0.3rem;
			@include heading-regular;
	}
	&__detail{
		color: rgba(60,60,60,0.4);
		font-size:1.4rem;
	}
	&__title{
		font-size: 2rem;


	}
}

@include media-breakpoint-down(md) {
	.registry-item {
	    display: block;
	    .registry-item__badges{
	    	margin-top: 10px;
	    }
	}

	.member-type-legend {
		.member-type {
		    width: 50%;
		}
	}


}