
.contact-details{

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	padding-left: 0;
	grid-gap: 30px;
	list-style: none;
	li{
		h5{
			text-transform: inherit;
			font-weight: 600;
			letter-spacing: 0;
		}
		a{
			color: $link-highlight;
			margin-top: 0;
			&:hover{
				color: $link-highlight;
			}
			&.more{
				color: $link-highlight;
				
				svg{
					*{
						color: $link-highlight;
					}
				}
			}
		}
	}
}


body{
	&.contact{
		background-color: $brand-quinary-light; 

		.form-group{
			label{
				text-transform: inherit;
			}
		}
	}
}
.contact-details{

	& + .socials{
		h5{
			text-transform: inherit;
			font-weight: 600;
			letter-spacing: 0;
		}
	}
}

.socials {
	margin-bottom: 30px;
	margin-top: 30px;
	padding-left: 0;
	list-style: none;
	h5{

	}
	span {
		display: inline-block;
		a{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;
			border-radius: 100%;
			background-color: $brand-color;
			margin-right: 15px;
			svg{
				color: #fff;
			}
		}
	}
}
